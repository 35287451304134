// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-md-2.container.mt-4.px-3.schedule-list-styles {
    background: #E2E2E2;
    border-radius: 3px;
    padding-top: 8px;
}

#heading-style{
    font-weight: bold;
    padding: 4px;
    font-size: 23px;
}
.schedule-list-styles h6{
    width:96%;
    text-align: center;
}
.schedule-list-styles button{
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/ScheduleLists/styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB","sourcesContent":[".col-md-2.container.mt-4.px-3.schedule-list-styles {\n    background: #E2E2E2;\n    border-radius: 3px;\n    padding-top: 8px;\n}\n\n#heading-style{\n    font-weight: bold;\n    padding: 4px;\n    font-size: 23px;\n}\n.schedule-list-styles h6{\n    width:96%;\n    text-align: center;\n}\n.schedule-list-styles button{\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
