import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AdvertisementSection from "../components/Advertisement/AdvertisementSection";
import Quotes from "../components/Quotes/Quotes";
import ScheduleLists from "../components/ScheduleLists/ScheduleLists";
import ScheduleDescription from "../components/SheduleDescription/ScheduleDescription";
import * as ScheduleApi from "../apis/schedule.api"
import { scheduleDetailsOrganzier } from "../utils";
import { updateScheduleDetails } from "../store/schedule/scheduleSlice";
import { updateScheduleListDetails } from "../store/scheduleList/scheduleListSlice";
import Loader from "../components/Loader";

const ScheduleDetails = () => {
    const {scheduleListDetails} = useSelector(state => state.scheduleList)
    const [tableData,setTableData] = useState({ data:[], taskView: "taskViewByDay", loading: true , filePath :'' })
    const [isPurchased, setIsPurchased] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    console.log("location.stateeeeeeeeeeeeeeeee", location?.state)

    useEffect(()=>{
    
        retrieveUserDetails()
      },[])
    
      const retrieveUserDetails=async()=>{
        try {
          setTableData((prevState)=>({...prevState, loading:true}))
          const userId  = localStorage.getItem("userId")
          const data  = {
            taskView : tableData.taskView , 
            userId : userId
          }
          const response = await ScheduleApi.scheduleListDetails(data);
          if(response?.status){
            const data = JSON.parse(response?.json)
            const scheduleId = location?.state?.scheduleId ?? data[0]?.scheduleId
            const taskView = location?.state?.taskView ?? tableData.taskView
            if(data?.length >0){
              const organizedData = scheduleDetailsOrganzier(data,scheduleId)
              const updatedRes ={
                json : JSON.stringify(organizedData),
                status: true,
                filePath :organizedData["excelUrl"],
                taskView : taskView,
                payment_status : organizedData["payment_status"],
                trackingStarted : organizedData["trackingStarted"],
                scheduleId : organizedData["scheduleId"],
                rebaseStarted : organizedData["rebaseStarted"],
                rebaseHours : organizedData["rebaseHours"],
                googleCalenderSSO:organizedData['googleCalenderSSO'],
                
              }
              dispatch(updateScheduleDetails(updatedRes))
              dispatch(updateScheduleListDetails(data))
              setTableData({data : organizedData, taskView : tableData.taskView, loading: false,filePath: organizedData?.excelUrl ?? ""})  
            }   
          }
          setTableData((prevState)=>({...prevState, loading:false}))
                     
        } catch (error) {
            console.error("error while retrieveing user details", error)
        }
      }
  return (
    <>
     {tableData?.loading && <Loader show={tableData?.loading} />}
      <Quotes />
      <div className="row m-2">
       {scheduleListDetails?.length>0 && <ScheduleLists setTableData={setTableData}  setIsPurchased={setIsPurchased} screen={"ScheduleDetails"}  />}
       {(location?.state?.learningItem?.details || location?.state?.learningItem?.["At_Bedtime_Everynight"]) && <ScheduleDescription details={location?.state} />}
        <AdvertisementSection />
      </div>
    </>
  );
};

export default ScheduleDetails;
