export const classes = {
    allocateContent: {
        maxWidth: "80%",
        textAlign: "center",
        marginTop: "10%",
        marginLeft: "7%",
        border: "1px solid black",
        height: "200%",
        padding: "3% 8% 2% 8%",
        fontSize:'130%'
      },
      rescheduleContent: {
        width: "80%",
        textAlign: "center",
        marginTop: "1%",
        marginLeft: "0",
        margin:'auto',
        border: "1px solid black",        
        padding: "2% 8% 2% 8%",
        fontSize:'130%'
      },
    content:{
        
         
          textAlign: "center",
          marginTop: "10%",
          margin: "auto",
          border: "1px solid black",
          height: "100px",
          paddingTop: "2%",
          fontWeight:"600",
          fontSize:"130%",
          "@media (min-width: 768px)": { // Correctly formatted media query
            maxWidth: "80%",
            textAlign: "center",
            marginTop: "10%",
            margin: "auto",
            border: "1px solid black",
            height: "100px",
            paddingTop: "2%",
            fontWeight:"600",
            fontSize:"160%",
        }
        
      },
      customMaxWidth: { // Use camelCase for consistency in JS objects
        // Default to 100%, then change at larger screen sizes
       "@media (min-width: 768px)": { // Correctly formatted media query
           maxWidth: "80%",
       }
   },
  continueBtn:{
    
      width: "30%",
      color: "black",
      border: "1px solid black",
      bgcolor: "#E9AB17",
      "&:hover": {
        bgcolor: "#FFFF99",
      },
    
  },
  rescheduleContentTable:{
    width:'80%',
    margin:'auto',
    border:'solid 1px #000'
  },
  rescheduleContentTableCell:{
    padding:'8px 15px',
    border:'solid 1px #000'
  },
  seprator:{
    fontSize:'18px'
  }
}