import React from "react";
import Quotes from "../components/Quotes/Quotes";
import PasswordReset from "../components/ForgetPassword/PasswordReset";
import "../styles/index.css";

const ResetPassword = () => {
  return (<>
      <Quotes/>
      <PasswordReset/>
  </>
  );
};

export default ResetPassword;