import apiClient from "../apiClient";
export const chargePayment = async(data) => {
    console.log("dTi",data)
    try {
      const response = await apiClient.post(`/payment/create-payment-intent`,data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const chargeAmount = async() => {

    try {
      const response = await apiClient.post(`/payment/get-payment-amount`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const promoCode = async(data) => {
    console.log("dTi",data)
    try {
      const response = await apiClient.post(`/payment/promoCode`,data);
      return response.data;
    } catch (error) {
      return error;
    }
  };