import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  scheduleListDetails:[]
}

export const scheduleListSlice = createSlice({
  name: 'scheduleList',
  initialState,
  reducers: {
    updateScheduleListDetails: (state, { payload }) => {
      state.scheduleListDetails = payload;
    }
  },
})

export const { updateScheduleListDetails } = scheduleListSlice.actions;

export default scheduleListSlice.reducer;
