import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ClearIcon from "@mui/icons-material/Clear";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 723,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding:"21px",
};

export default function PaymentCompleteModal({
  paymentCompleted,
  setPaymentCompleted,
}) {
  console.log("hithere33333",paymentCompleted)
  return (
    <Modal
      open={paymentCompleted}
      onClose={() => setScheduleCompleted(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="d-flex  justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CelebrationIcon /> Congratulations !! Schedule is successfully
            completed !!
          </Typography>
          <ClearIcon onClick={() => setPaymentCompleted(false)} />
        </div>
      </Box>
    </Modal>
  );
}