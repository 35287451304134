import apiClient from "../apiClient";


export const getCurrentUser = async(data) => {
    try {
      const response = await apiClient.post(`/user/currentUser`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  // /create-user


  export const createUser = async(data) => {
    try {
      const response = await apiClient.post(`/user/create-user`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };


  export const logInUser = async(data) => {
    try {
      const response = await apiClient.post(`/user/sign-in`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const scheduleCompletedTag = async(userId) => {
    console.log("datauserid",userId)
    try {
      const response = await apiClient.post(`/user/schedule-Completed`, { data: userId });
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const Chatbot = async(data) => {
    try {
      const response = await apiClient.post(`/user/promptApi`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const FetchChatbotInfo = async(data) => {
    try {
      const response = await apiClient.post(`/user/FetchChatbotInfo`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  