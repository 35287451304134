import React from 'react';

const TermsAndConditions = () => {
  const containerStyle = {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  };

  const headingStyle = {
    color: '#2c3e50',
    borderBottom: '2px solid #ecf0f1',
    paddingBottom: '10px',
  };

  const subHeadingStyle = {
    color: '#2980b9',
    marginTop: '20px',
  };

  const paragraphStyle = {
    marginBottom: '15px',
    textAlign: 'justify',
  };

  const linkStyle = {
    color: '#2980b9',
    textDecoration: 'none',
  };

  const listStyle = {
    margin: '10px 0',
    paddingLeft: '20px',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Free PMP Baseline Exam</h1>
      <p style={paragraphStyle}>Do You Have What it Takes to Pass the PMP Exam?</p>
      <p style={paragraphStyle}>
        If you have problems using the Spot On PMP Practice Questions app, contact <a href="mailto:support@cheetahlearning.com" style={linkStyle}>support@cheetahlearning.com</a> or call 844-800-4PMP (844-800-4767).
      </p>

      <h1 style={headingStyle}>End User License Agreement (EULA)</h1>
      <p style={paragraphStyle}>
        This End-User License Agreement (“EULA”) is a legal agreement between you and Cheetah Learning LLC.
        This EULA agreement governs your acquisition and use of our Spot On PMP software (“Software”) directly from Cheetah Learning LLC or indirectly through a Cheetah Learning LLC authorized reseller or distributor (a “Reseller”).
      </p>
      <p style={paragraphStyle}>
        Please read this EULA agreement carefully before completing the installation process and using the Spot On PMP software. It provides a license to use the Spot On PMP software and contains warranty information and liability disclaimers.
      </p>
      <p style={paragraphStyle}>
        If you register for a free trial of the Spot On PMP software, this EULA agreement will also govern that trial. By clicking “accept” or installing and/or using the Spot On PMP software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.
      </p>
      <p style={paragraphStyle}>
        If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.
      </p>
      <p style={paragraphStyle}>
        This EULA agreement shall apply only to the Software supplied by Cheetah Learning LLC herewith regardless of whether other software is referred to or described herein. The terms also apply to any Cheetah Learning LLC updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply. This EULA was created by EULA Template for Spot On PMP.
      </p>
      
      <h2 style={subHeadingStyle}>License Grant</h2>
      <p style={paragraphStyle}>
        Cheetah Learning LLC hereby grants you a personal, non-transferable, non-exclusive licence to use the Spot On PMP software on your devices in accordance with the terms of this EULA agreement.
      </p>
      <ul style={listStyle}>
        <li style={paragraphStyle}>You are permitted to load the Spot On PMP software on to one device (for example a PC, laptop, mobile, or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the Spot On PMP software.</li>
        <li style={paragraphStyle}>You have one access license to use Cheetah’s Spot On PMP Practice Test Questions. You are allowed to sign into your account from one device at a time. If you are signed into your account from more than one device at the same time, your account will be deactivated immediately.</li>
        <li style={paragraphStyle}>You have access to Cheetah’s Spot On PMP Practice Test Questions from the time you register until the time you complete your Cheetah Exam Prep for the PMP program.</li>
        <li style={paragraphStyle}>If you want extended access to Cheetah’s Spot On PMP Practice Test Questions beyond your registered course completion, you must register for Cheetah’s Three Month Spot On PMP Mobile Prep program.</li>
      </ul>
      <p style={paragraphStyle}>
        You are not permitted to:
      </p>
      <ul style={listStyle}>
        <li style={paragraphStyle}>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things.</li>
        <li style={paragraphStyle}>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose.</li>
        <li style={paragraphStyle}>Allow any third party to use the Software on behalf of or for the benefit of any third party.</li>
        <li style={paragraphStyle}>Use the Software in any way which breaches any applicable local, national or international law.</li>
        <li style={paragraphStyle}>Use the Software for any purpose that Cheetah Learning LLC considers is a breach of this EULA agreement.</li>
      </ul>

      <h2 style={subHeadingStyle}>Intellectual Property and Ownership</h2>
      <p style={paragraphStyle}>
        Cheetah Learning LLC shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Cheetah Learning LLC.
      </p>
      <p style={paragraphStyle}>
        Cheetah Learning LLC reserves the right to grant licences to use the Software to third parties.
      </p>

      <h2 style={subHeadingStyle}>Termination</h2>
      <p style={paragraphStyle}>
        This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to Cheetah Learning LLC.
      </p>
      <p style={paragraphStyle}>
        It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.
      </p>

      <h2 style={subHeadingStyle}>Governing Law</h2>
      <p style={paragraphStyle}>
        This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of the United States.
      </p>

      <h2 style={subHeadingStyle}>Compliance with Google API Services User Data Policy</h2>
      <p style={paragraphStyle}>
        Our use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. For more information, please visit the <a href="https://developers.google.com/terms/api-services-user-data-policy" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>Google API Services User Data Policy</a>.
      </p>

      <h1 style={headingStyle}>FAQs</h1>
      <h2 style={subHeadingStyle}>1. What are the advantages to using Cheetah’s Accelerated Exam Prep System to Pass the PMP Exam?</h2>
      <p style={paragraphStyle}>
        This app is designed to help you target and focus your study efforts for passing the PMP exam. In Cheetah’s Accelerated Exam Prep program you learn how to best integrate your experience with the required knowledge needed to pass the PMP exam. Since most people know how difficult this exam is, it is seen as a huge accomplishment to study for and pass the exam in one week. Our students report that it actually puts them a notch above the other people in their organizations who had to study for a year or two – and it should. In the market, if a company takes a year or more to get a product to market versus a company that takes a week to get the same product to market, which company would have greater market share and the higher stock price?
      </p>

      <h2 style={subHeadingStyle}>2. Where and when can I take a Cheetah Exam Prep course?</h2>
      <p style={paragraphStyle}>
        Cheetah offers live virtual and in-person four day courses throughout the year, with the majority of courses being offered in the fall and the spring to accommodate when the majority of students can fit in the training amidst their other responsibilities. Throughout the year students can also take a four week online program that is based on their schedules.
      </p>

      <h2 style={subHeadingStyle}>3. What is involved in an actual Cheetah Exam Prep for the PMP class?</h2>
      <p style={paragraphStyle}>
        You participate in an event that uses well-proven accelerated learning techniques to ensure you are able to take and pass the PMP® exam immediately after you complete the class. You do not need to do more studying.
      </p>
      <ul style={listStyle}>
        <li style={paragraphStyle}>You learn mnemonics for instant recall of key PM concepts for the exam.</li>
        <li style={paragraphStyle}>You learn advanced highly effective test-taking strategies for the PMP® Exam.</li>
        <li style={paragraphStyle}>You take daily baseline exams and do supplemental exam questions after every PMBOK® Guide content area (600+ test questions to prepare you for the actual exam).</li>
        <li style={paragraphStyle}>You learn and do rapid synthesis techniques for understanding the PMBOK® Guide content (the overall PM Framework and all 10 PM Knowledge areas) you need to know for the exam.</li>
        <li style={paragraphStyle}>You use accelerated learning study aids to improve your ability to instantly recall PMBOK® Guide content and other key PM concepts for the exam.</li>
        <li style={paragraphStyle}>You use advanced listening techniques informed by neuroscience to effortlessly learn and remember key concepts for the exam and to improve your concentration and focus while taking the exam.</li>
        <li style={paragraphStyle}>You do a number of relaxation exercises to ensure that you can maintain your concentration and focus through the course and in the exam.</li>
        <li style={paragraphStyle}>You are completely immersed throughout the four days with highly interactive activities that are all designed to ensure your success with passing the PMP exam.</li>
        <li style={paragraphStyle}>Many other people comment that this course is the most invigorating, positive, and educational experience of their entire academic careers.</li>
        <li style={paragraphStyle}>The class runs from 8 a.m to 6 p.m. every day, with one to two hours of homework each night.</li>
      </ul>
    </div>
  );
}

export default TermsAndConditions;
