import React, { useEffect, useState } from 'react';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import './Chatbot.css';
import * as UserApi from '../../apis/user.api';
import AdvertisementSection from '../Advertisement/AdvertisementSection';
import { useLocation } from "react-router-dom";
const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [initialMessage, setInitialMessage] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const chatbotId = query.get('id');
  console.log("printid",chatbotId)
  useEffect(() => {
    const fetchChatbotInfo = async () => {
      try {
        const updatedRes = await UserApi.FetchChatbotInfo({ chatbotId: chatbotId });
        setInitialMessage(updatedRes.dbPrompt);
      } catch (error) {
        console.error('Failed to fetch chatbot info:', error);
      }
    };

    fetchChatbotInfo();
  }, [chatbotId]);
  const handleSend = async (message) => {
    setMessages((prevMessages) => [...prevMessages, { text: message, sender: 'user' }]);
    setLoading(true); // Set loading to true when message is sent
    try {
      if (message) {
        const updatedRes = await UserApi.Chatbot({ userQuery: message,chatbotId:chatbotId });
        const result = JSON.parse(updatedRes.message);
        console.log('updatedRes1111', result.ai_reply,updatedRes.dbPrompt);
       
        // Add the chatbot's reply to the messages
        setMessages((prevMessages) => [...prevMessages, { text: result.ai_reply, sender: 'bot' }]);
      }
    } catch (error) {
      console.error('Error fetching chatbot reply:', error);
    }
    setLoading(false); // Set loading to false after response is received
  };
  return (
    <div className="mt-5">
      <div className="row p-3">
        <div className='col-lg-1 col-md-2' />
        <div className="col-lg-9 col-md-7">
          <div className="card mt-4">
            <div className="card-header" style={{ color: '#f9f9f9', backgroundColor: '#3f51b5' }}>Cheetah Chatbot</div>
            <div className="card-body">
              <ChatWindow messages={messages}onSend={handleSend}loading={loading} initialMessage={initialMessage}/>
            </div>
            <div className="card-footer">
              <ChatInput onSend={handleSend} />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3">
          <div className="advertisement-section sticky-top" style={{ top: '20px' }}>
            <AdvertisementSection />
          </div>
        </div>
      </div>
    </div>
  );
}  

export default Chatbot;
