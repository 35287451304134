import React, { useEffect, useState,useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { countCompletedAndPending, ProgressCalculator, sumUpTotalDuration } from "../../utils";
import { topicByDayOrganizer, topicByDayRendrer } from "../../utils/topicByDayUtil";
import ScheduleCompletionModal from "../ScheduleCompletionModal/ScheduleCompletionModal";
// import SelectRescheduleDate from "./SelectRescheduleDate";
import SelectRescheduleDate from "./SelectRescheduleDate";
import TaskDetailsModal from "./TaskDetailModal";
import {scheduleCompletedTag} from './../../apis/user.api'
import "./styles.css";
import Loader from "../Loader";

const DayWiseTaskList = ({setTableData,tableData,taskViewByDayData,setTaskViewByDayData,setShowPaymentModal,showPaymentModal,isPurchased,hasCompletedRef}) => {

  const [openTaskDetails, setOpenTaskDetails] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [taskList, setTaskList]= useState({data :{}, itemNumber:null})
  const [scheduleCompleted, setScheduleCompleted] = useState(false)
  const {rebaseStarted,rebaseHours,trackingStarted} = useSelector(state => state.schedule)  
  const {userId } = useSelector(state => state.user);
  const {scheduleId} = useSelector((state) => state.schedule)
  const [progress, setProgress] = useState("0")

  // console.log("tabletabletabletabletabletabletabletabletabletabletabletabletabletabletable", JSON.stringify(table))
  // console.log("DayWiseTaskListDayWiseTaskList ",tableData)

  useEffect(() => {
    async function processTableData() {
     
      if (tableData?.data) {
        let transformedData = topicByDayOrganizer(tableData.data, false);
  
        if (rebaseHours && rebaseStarted) {
          // Simplified assignment since rebaseStarted is already being checked
          transformedData = topicByDayRendrer(tableData.data, rebaseHours);
        }
  
        console.log("transformedData", transformedData);
      
        if (transformedData?.length > 0) {
       
          const calculateProgress = await ProgressCalculator(transformedData,userId,scheduleId);
          setTaskViewByDayData((prevData) => ({
            ...prevData,
            data: transformedData,
            progress: calculateProgress
          }));
          setTableData((prevState) => ({ ...prevState, loading: false }));
          console.log("userssssid12",calculateProgress)
          if (calculateProgress === 100 && !hasCompletedRef.current) {
            console.log("userssssid11",userId)
            await scheduleCompletedTag(userId);
            setScheduleCompleted(true);
            // Uncomment or modify the toast message as needed
            // toast.success("Congratulations !!, Schedule is successfully completed.");
            hasCompletedRef.current = true; // Ensure the flag is set to true to prevent re-scheduling
          }
         
         
        }
      }
    }
  
    processTableData();
  }, [tableData?.data, userId]);
  

  useEffect(() => {
    if(taskViewByDayData.length >0 ){
 
      const calculateProgress = ProgressCalculator(transformedData)
      setTaskViewByDayData((prevData) => ({...prevData , progress :calculateProgress}))

    }

  }, []);


  const isCompleted = (studyPlanData) => {
    let completedCount = 0;
    let pendingCount = 0;
    if(studyPlanData?.data?.length >0){
      studyPlanData?.data?.forEach((item) => {
        if(item?.title == "Do"){
           console.log("Skipping Do Element")
        }else if (item.status === "Completed") {
          completedCount++;
        } else if (item.status === "Pending") {
          pendingCount++;
        }
      });

      const totalCount  = completedCount+pendingCount
      if(completedCount >0 && totalCount >0){
        const percentageCompleted = (completedCount / totalCount) * 100;
        return percentageCompleted

      }
    }

    return false;
  };

  //const isAnyContentBlurred = table.some((item, index) => index > 3);
  const openTaskListDetails = (item, itemNumber) => {
    if (!isPurchased && itemNumber > 3) { // Day4 and onwards
      setShowPaymentModal(true);
    } else {
        setTaskList({ data: item, itemNumber: itemNumber  });
        setOpenTaskDetails(true);
      // if(trackingStarted){

      //   setTaskList({ data: item, itemNumber: itemNumber  });
      //   setOpenTaskDetails(true);
      // }
      // else{
      //   setShowModal(true)
      // }
    }
  };
  const applyStyles = (item, dayIndex) => {
    const isBlurred = !isPurchased && dayIndex > 3;
    const completedPercentage = isCompleted(item);
    const styles = { cursor: 'pointer' };

    if (isBlurred) {
      styles.filter = 'blur(4px)';
    }
    
    if (completedPercentage) {
      styles.backgroundImage = `linear-gradient(to right, #7fd37f ${completedPercentage}%, transparent 50%)`;
    }

    return styles;
  };
console.log("taskViewByDayData",taskViewByDayData)
const startTracking = () => {
  console.log("trackingStartedtrackingStarted", trackingStarted)
  if (!trackingStarted) {
    setShowModal(true);
  } else {
    toast.error("Tracking is already started !!")
  }
};

console.log("trackingStartedtrackingStarted",trackingStarted)
  return (
    <>
    
    <ScheduleCompletionModal  scheduleCompleted={scheduleCompleted}  setScheduleCompleted={setScheduleCompleted} />
    <SelectRescheduleDate showModal={showModal} setShowModal={setShowModal} setTableData={setTableData} taskViewByDayData={taskViewByDayData} table={taskViewByDayData.data} isFromDay={true}/>
    {taskList?.data && taskList?.itemNumber!=null && <TaskDetailsModal  openTaskDetails={openTaskDetails} setShowPaymentModal={setShowPaymentModal}setOpenTaskDetails={setOpenTaskDetails} taskList={taskList} setTableData={setTableData}
     tableData={taskViewByDayData}  taskView="taskViewByDay" /> }
       <div className="row">
       <div className="col-md-3 col-sm-12">
       <button
        type="button"
        className="btn btn-warning homepage-btn-style study-plan-action-style mt-3 col-md-2"
        style={{ backgroundColor: "#1155cc", color: "white", border:"1px solid black" ,width:"17rem"  }}
        onClick={() => { startTracking() }}
      >
        Start Tracking
      </button>
       </div>
   
    </div>

      <div className="row mt-3" style={{position:'relative'}}>
        <div
          className="col-md-11"
          style={{ maxHeight: "600px", overflowY: "scroll" }}
        >



        {taskViewByDayData?.data?.length > 0 &&
       taskViewByDayData?.data?.map((item, dayIndex) => {
          const isBlurred = dayIndex > 3;
          return (
            <div className={`row mb-1`} key={dayIndex}>
              <div
                className={`col-md-3 mr-2 daywise-tasklist-col-style ${
                  !isPurchased && dayIndex > 3 ? "blurred-content" : ""
                }`}
              >
                {item?.data[0]?.date && item?.data[0]?.date != " "
                  ? item?.data[0]?.date
                  : item?.day === "Day1"
                  ? "Current Day"
                  : item?.day}
              </div>
              <div
                className="col daywise-tasklist-col-style"
                onClick={() => openTaskListDetails(item, dayIndex)}
                style={applyStyles(item, dayIndex)}
              >
                {`${item?.data[0]?.title}...`}{" "}
                <span
                  style={{
                    color:
                      isCompleted(item) === 100 ? "rgb(127, 211, 127)" : "",
                  }}
                  className="total-calculation-css"
                >
                  {sumUpTotalDuration(item?.data)}
                </span>
              </div>
            </div>
          );
        })}
       
        </div>
        { !isPurchased &&  (
        
          <button
            type="button"
            className="btn btn-warning homepage-btn-style study-plan-action-style mt-3 col-md-2 d-flex justify-content-center align-items-center  daywise-pruchase-btn"
            
            onClick={() => setShowPaymentModal(true)}
          >
            Purchase To Continue
          </button>
        
      )}
        <div className="col d-flex justify-content-end">
          <div className="progress progress-bar-vertical">
            <div
              className="progress-bar progress-striped"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ height: `${taskViewByDayData?.progress}%`, backgroundColor: "#93c47d" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DayWiseTaskList;
