import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Dashboard from "../pages/Dashboard";
import Homepage from "../pages/Homepage";
import SetupTopics from "../pages/SetupTopics";
import RescheduleStudyPlan from '../pages/RescheduleStudyPlan'
import PrivateRoute from "./PrivateRoute";
import My404Component from "../components/404Page/404Page";

import ChangePassword from "../pages/ChangePassword";
import ResetPassword from "../pages/ResetPassword";
import ScheduleDetails from "../pages/ScheduleDetails";
import Chatbot from "../components/chatbot/Chatbot";
import PrivacyPolicy from "../components/RegistrationFrom/PrivacyPolicy";
import TermsAndConditions from "../components/RegistrationFrom/TermsAndConditions";
import Blogs from "../pages/Blogs";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navbar />}>
        <Route index element={<Homepage />} />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="proficiency-setup"
          element={
            <PrivateRoute>
              <SetupTopics />
            </PrivateRoute>
          }
        />
        <Route
          path="ChatBot-Ai"
          element={
            <PrivateRoute>
              <Chatbot />
            </PrivateRoute>
          }
        />
        <Route
          path="reschedule"
          element={
            <PrivateRoute>
              <RescheduleStudyPlan />
            </PrivateRoute>
          }
        />
        <Route
          path="schedule-details"
          element={
            <PrivateRoute>
              <ScheduleDetails />
            </PrivateRoute>
          }
        />
           <Route path="terms-conditions" element={<TermsAndConditions />} />
        <Route path="forget-password" element={<ChangePassword />} />
        <Route path="exams" element={<Blogs />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
      </Route>
      <Route path='*' exact={true} element={<My404Component />} />
      <Route path='404-page' element={<My404Component />} />

    </Routes>
  );
};

export default Routers;