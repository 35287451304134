import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import scheduleReducer from './schedule/scheduleSlice'
import scheduleListReducer from './scheduleList/scheduleListSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    schedule: scheduleReducer,
    scheduleList : scheduleListReducer
  }
})