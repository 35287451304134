import React, { useEffect, useState } from "react";
import AdvertisementImg from "../../assets/images/advertisement.jpg";
import * as MarketingApi from "../../apis/marketingContent.api";
import { useLocation } from "react-router-dom";
import { ADMIN_URL } from "../../constants/envConstants";

const AdvertisementSection = () => {
  const [marketingContent, setMarketingContent] = useState(null);

  useEffect(() => {
    fetchMarketingContent();
  }, []);

  async function fetchMarketingContent() {
    try {
      const updateRes = await MarketingApi.getMarketingContent();
      if(updateRes?.status){
        setMarketingContent(updateRes);
      }
    } catch (error) {
      console.error("Failed to fetch marketing content:", error);
    }
  }

  return (
    <div className="container col  d-flex flex-column mt-4 justify-content-start me-md-2 mx-2 " >
       {marketingContent &&
        marketingContent?.marketingContentRes?.length>0 &&
        marketingContent?.marketingContentRes.map((content, index) => (
          <a
            key={index}
            href={content?.contentUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${ADMIN_URL}/${content?.contentPath}`}
              alt="Advertisement Image"
              className="img-fluid  mb-2"
            />
          </a>
        ))}
      {!marketingContent && (
        <>
          <img
            src={AdvertisementImg}
            alt="advertisement image"
            className="img-fluid  mb-2"
          />
          <img
            src={AdvertisementImg}
            alt="advertisement image"
            className="img-fluid  mb-2"
          />
        </>
      )}
    </div>
  );
};

export default AdvertisementSection;
