import React from 'react';

function Blogs() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <iframe 
                src="https://exams.cheetahstudyplan.com/listing" 
                style={{ width: "90%", height: "110vh", border: "none", overflow: "hidden" }}
                title="Blogs"
                scrolling="no"
            ></iframe>
        </div>
    );
}

export default Blogs;
