import React, { useEffect, useState } from "react";
import { countCompletedAndPending, ProgressCalculator } from "../../utils";
import { topicOrganizer } from "../../utils/topicByDayUtil";
import ScheduleCompletionModal from "../ScheduleCompletionModal/ScheduleCompletionModal";
import SelectRescheduleDate from "./SelectRescheduleDate";
import TaskDetailsModal from "./TaskDetailModal";
import { toast } from "react-toastify";
import { scheduleCompletedTag } from "../../apis/user.api";
import { useSelector } from "react-redux";
import "./styles.css";
const TopicWiseTaskList = ({ setTableData, tableData, taskViewByDayData, setTaskViewByDayData, isPurchased,setShowPaymentModal,trackingStarted ,setShowModal,has1CompletedRef}) => {



    const [openTaskDetails, setOpenTaskDetails] = useState(false)
    const [taskList, setTaskList] = useState({ data: {}, itemNumber: null })
    const [scheduleCompleted, setScheduleCompleted] = useState(false)
    const {userId } = useSelector(state => state.user);
    const [progress, setProgress] = useState("0")

    // console.log("tabletabletabletabletabletabletabletabletabletabletabletabletabletabletable", JSON.stringify(table))
    // console.log("DayWiseTaskListDayWiseTaskList ",tableData)
    // console.log("tableData", tableData.data)


    useEffect(() => {
        // Define an async function to handle operations that require awaiting
        const processData = async () => {
            const { data } = tableData || {};
            if (data) {
                const transformedData = topicOrganizer(data);
                console.log("transformedData", transformedData);
                setTableData((prevState) => ({ ...prevState, loading: false }));
                if (transformedData.length > 0) {
                 
                    const calculateProgress = await ProgressCalculator(transformedData);
                    setTaskViewByDayData(prevData => ({
                        ...prevData,
                        data: transformedData,
                        progress: calculateProgress
                    }));
                    // Check progress and ref state before scheduling the tag
                    if (calculateProgress === 100 && !has1CompletedRef.current) {
                        try {
                            // Await the asynchronous operation
                            await scheduleCompletedTag(userId);
                            setScheduleCompleted(true);
                            has1CompletedRef.current = true;
    
                            // Uncomment or modify the toast message as needed
                            // toast.success("Congratulations !!, Schedule is successfully completed.");
                        } catch (error) {
                            console.error('Failed to schedule completed tag:', error);
                            // Optionally handle errors, e.g., with a toast message
                            // toast.error("Error completing schedule.");
                        }
                    }
                    // Update state irrespective of progress completion
                    
                }
            }
        };
    
        // Call the async function
        processData();
    }, [tableData?.data]); // Ensure to destruct data from tableData in the dependency array
    
    // Ensure you have defined all necessary hooks, state, and refs
    

    useEffect(() => {
        if (taskViewByDayData.length > 0) {

            const calculateProgress = ProgressCalculator(transformedData)
            setTaskViewByDayData((prevData) => ({ ...prevData, progress: calculateProgress }))

        }

    }, [openTaskDetails]);


    const isCompleted = (studyPlanData) => {
        let completedCount = 0;
        let pendingCount = 0;
        if (studyPlanData?.data?.length > 0) {
            studyPlanData?.data?.forEach((item) => {
                if(item?.title == "Do"){
                    console.log("Skipping Do Element")
                 }
                else if (item.status === "Completed") {
                    completedCount++;
                } else if (item.status === "Pending") {
                    pendingCount++;
                }
            });

            const totalCount = completedCount + pendingCount
            if (completedCount > 0 && totalCount > 0) {
                const percentageCompleted = (completedCount / totalCount) * 100;
                return percentageCompleted

            }
        }

        return false;
    };
// console.log("taskViewByDayData",taskViewByDayData)
    //const isAnyContentBlurred = table.some((item, index) => index > 3);
    const openTaskListDetails = (item, itemNumber) => {
        if (!isPurchased && itemNumber > 3) { // Day4 and onwards
            setShowPaymentModal(true);
        } else {
            setTaskList({ data: item, itemNumber: itemNumber  });
                setOpenTaskDetails(true);
            // if(trackingStarted){

            //     setTaskList({ data: item, itemNumber: itemNumber  });
            //     setOpenTaskDetails(true);
            //   }
            //   else{
            //     setShowModal(true)
            //   }
        }
    };
    const applyStyles = (item, dayIndex) => {
        const isBlurred = !isPurchased && dayIndex > 3;
        const completedPercentage = isCompleted(item);
        const styles = { cursor: 'pointer' };

        if (isBlurred) {
            styles.filter = 'blur(4px)';
        }

        if (completedPercentage) {
            styles.backgroundImage = `linear-gradient(to right, #7fd37f ${completedPercentage}%, transparent 50%)`;
        }

        return styles;
    };
console.log("taskViewByDayData",taskViewByDayData)
    return (
        
        <div className="mt-3">
            <ScheduleCompletionModal  scheduleCompleted={scheduleCompleted}  setScheduleCompleted={setScheduleCompleted} />
          

            {taskList?.data && taskList?.itemNumber != null && <TaskDetailsModal setShowPaymentModal={setShowPaymentModal}openTaskDetails={openTaskDetails} setOpenTaskDetails={setOpenTaskDetails} taskList={taskList} setTableData={setTableData}
                tableData={taskViewByDayData} taskView="taskViewByTopic" />}
            <div className="row">
                <div className="col-md-3"></div>
            </div>

            <div className="row mtByDay-3">
                <div
                    className="col-md-11"
                    style={{ maxHeight: "600px", overflowY: "scroll" }}
                >


                    {taskViewByDayData?.data?.length > 0 &&
                        taskViewByDayData?.data?.map((item, dayIndex) => {
                            const isBlurred = dayIndex > 3;
                            return (
                                <div className={`row mb-1`} key={dayIndex}>
                                    <div className={`col-md-3 mr-2 daywise-tasklist-col-style ${!isPurchased && dayIndex > 3 ? 'blurred-content' : ''}`}>
                                        {item?.day === 'Day1' ? 'Current Day' : (item?.day).replace(/_/g, " ")}
                                    </div>
                                    <div
                                        className="col daywise-tasklist-col-style"
                                        onClick={() => openTaskListDetails(item, dayIndex)}
                                        style={applyStyles(item, dayIndex)}
                                    >

                                        {`${item?.data[0]?.title}...`}
                                    </div>
                                </div>
                            );
                        })}
                    {!isPurchased && (

                        <button
                            type="button"
                            className="btn btn-warning homepage-btn-style study-plan-action-style mt-3 col-md-2 d-flex justify-content-center align-items-center purchase-btn "
                            
                            onClick={() => setShowPaymentModal(true)}
                        >
                            Purchase To Continue
                        </button>

                    )}
                </div>
                <div className="col d-flex justify-content-end">
                    <div className="progress progress-bar-vertical">
                        <div
                            className="progress-bar progress-striped"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ height: `${taskViewByDayData?.progress}%`, backgroundColor: "#93c47d" }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopicWiseTaskList;