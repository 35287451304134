// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group {
    margin-top: 10px;
  }
  
  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
 
  
  .btn-gradient:hover {
    background: linear-gradient(to right, #8f94fb, #4e54c8);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chatbot/ChatInput.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,0BAA0B;IAC1B,6BAA6B;EAC/B;;;;EAIA;IACE,uDAAuD;EACzD","sourcesContent":[".input-group {\n    margin-top: 10px;\n  }\n  \n  .form-control {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n  \n \n  \n  .btn-gradient:hover {\n    background: linear-gradient(to right, #8f94fb, #4e54c8);\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
