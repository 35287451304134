import React from "react";
import Quotes from "../components/Quotes/Quotes";
import ForgetPassword from "../components/ForgetPassword/ForgetPassword";
import "../styles/index.css";

const ChangePassword = () => {
  return (<>
      <Quotes/>
      <ForgetPassword/>
  </>
  );
};

export default ChangePassword;