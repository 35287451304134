import React from 'react';

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  };

  const headingStyle = {
    color: '#2c3e50',
    borderBottom: '2px solid #ecf0f1',
    paddingBottom: '10px',
  };

  const subHeadingStyle = {
    color: '#2980b9',
    marginTop: '20px',
  };

  const paragraphStyle = {
    marginBottom: '15px',
    textAlign: 'justify',
  };

  const linkStyle = {
    color: '#2980b9',
    textDecoration: 'none',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Privacy Policy</h1>
      <p style={paragraphStyle}>
        Cheetah study collects personal information such as your name, address, phone number, company name, e-mail address, and zip code when you register for any course. This information is kept in our secure database and is used to verify credit card transactions when you register and to contact and inform you about Cheetah study opportunities. We do not sell or rent our customer lists.
      </p>
      <p style={paragraphStyle}>
        Users who provide such information consent to the terms of this privacy policy and to Cheetah study’s use of that information. However, you can opt out of receiving e-mail notices and newsletters by selecting the unsubscribe option at the bottom of these communications.
      </p>
      <p style={paragraphStyle}>
        The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the Site. Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies and other tracking technology; however, if you previously provided personally identifiable information, cookies may be tied to such information.
      </p>
      <p style={paragraphStyle}>
        In the event you believe the information collected is in error, you can submit your request to review it to <a href="mailto:askcheetah@cheetahlearning.com" style={linkStyle}>askcheetah@cheetahlearning.com</a>. Please also include a daytime phone number where you can be reached. In order to protect your privacy and security, Cheetah Learning may take reasonable steps to verify a user’s identity before granting access or making corrections.
      </p>
      <p style={paragraphStyle}>
        Cheetah study has taken security measures to protect against loss, misuse, or alteration of payment and other information under the control of Cheetah study. Transaction and payment information is protected during transmission by https encryption over a secure socket layer, and information such as credit card numbers are not available to users or employees of Cheetah Learning. Additionally, the data is secured through various security measures in place at Cheetah Learning’s data center in accordance with standard business practices.
      </p>
      <p style={paragraphStyle}>
        Our use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. For more information, please visit the <a href="https://developers.google.com/terms/api-services-user-data-policy" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>Google API Services User Data Policy</a>.
      </p>
      <h2 style={subHeadingStyle}>Requesting Past Records</h2>
      <p style={paragraphStyle}>
        For a duplicate class certificate, log in to your student page at <a href="https://www.cheetahlearning.com" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>www.cheetahlearning.com</a> and select “Transcripts.” If you need a copy of your receipt, which includes the dates of your class, contact our accounting department at <a href="mailto:cheetahclass@cheetahlearning.com" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>cheetahclass@cheetahlearning.com</a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
