import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from "./pages/ErrorBoundary";
import Routers from "./routes";
import { getCurrentUser } from "./store/user/userAction";
import { FullStory } from '@fullstory/browser';

const App = () => {
  const { userToken, userId,userInfo } = useSelector(state => state.user);

  const dispatch = useDispatch()
  useEffect(()=>{
    console.log("getuserid",userId)
     if(userId && userToken){
      console.log("userInfo11",userInfo)
      dispatch(getCurrentUser({userId}))
      FullStory.identify(userId, {
        displayName: userInfo.firstName,
        email: userInfo.email,
        userType: userInfo.userType,
      });
     }
  },[])
  return (
    <ErrorBoundary>
    <ToastContainer />
      <Routers/>
    </ErrorBoundary>
  );
};

export default App;