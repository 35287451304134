import axios from "axios";
import { USER_TOKEN } from "./constants";
import { SERVER_URL } from "./constants/envConstants";

const apiClient = axios.create({
    baseURL: `${SERVER_URL}/api`,
  });

apiClient.interceptors.request.use(async (request) => {
    const accessToken = localStorage.getItem(USER_TOKEN);
    console.log("accessssssssToken", accessToken)
  
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
      // Check if the token is expired
      // const expiresAt = localStorage.getItem("expiresAt");
      // CURRENTLY COMMENTED BUT WILL UNCOMMENT IN FUTURE IF REQUIRED  ~ Jagroop
      // if (expiresAt) {
      //   const currentTime = new Date().getTime();
      //   const expirationTime = new Date(expiresAt).getTime();
      //   console.log("Access token", currentTime, expirationTime);
      //   if (currentTime > expirationTime) {
      //     // Token has expired, redirect to login
      //     console.log("token expired going to login page");
      //     localStorage.removeItem(SECRET_KEY); // Clear the expired token
      //     localStorage.removeItem("expiresAt"); // Clear the expiration time
      //     window.location.href = "/login"; // Replace with your login route
      //     return Promise.reject("Token expired"); // Reject the request
      //   }
      // }
  
      // Token is valid, set the Authorization header
    }
  
    return request;
  });
  


export default apiClient;