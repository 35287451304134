import React from "react";
//import Navbar from "../components/Navbar";
import Reschedule from "../components/RescheduleAndAllotment/Reschedule";
import "../styles/index.css";

const RescheduleStudyPlan = () => {
  return (<>
      <Reschedule/>
  </>
  );
};

export default RescheduleStudyPlan;