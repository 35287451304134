// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.activity-style {
    font-size: 19px;
    font-weight: 600;
}

.back-btn-style {
    background-color: #ffd966;
    padding: 10px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    border: 1px solid #cca8a8;
}`, "",{"version":3,"sources":["webpack://./src/components/SheduleDescription/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":["p.activity-style {\n    font-size: 19px;\n    font-weight: 600;\n}\n\n.back-btn-style {\n    background-color: #ffd966;\n    padding: 10px;\n    border-radius: 5px;\n    font-size: 17px;\n    font-weight: 500;\n    border: 1px solid #cca8a8;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
