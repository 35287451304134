// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-window {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.message {
  margin: 10px 0;
}
.card-header{
  background: linear-gradient(to right, #07275d,#07275d);

}
.advertisement-section {
  flex: 0 0 100%; /* Ensure it takes the full width of its container */
  max-width: 300px; /* Ensure it doesn't exceed a certain width */
}



`, "",{"version":3,"sources":["webpack://./src/components/chatbot/Chatbot.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,sDAAsD;;AAExD;AACA;EACE,cAAc,EAAE,oDAAoD;EACpE,gBAAgB,EAAE,6CAA6C;AACjE","sourcesContent":[".chat-window {\n  padding: 10px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  background-color: white;\n}\n\n.message {\n  margin: 10px 0;\n}\n.card-header{\n  background: linear-gradient(to right, #07275d,#07275d);\n\n}\n.advertisement-section {\n  flex: 0 0 100%; /* Ensure it takes the full width of its container */\n  max-width: 300px; /* Ensure it doesn't exceed a certain width */\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
