// import moment from "moment";
// import Cookies from "js-cookie";
// import { COOKIE_DOMAIN } from "../constants/envConstants";
const moment  = require("moment")
const Cookies = require("js-cookie")
const {COOKIE_DOMAIN} = require("../constants/envConstants")
import * as ScheduleApi from '../apis/schedule.api'
export function removePublicFromURL(url) {
    // Check if "public" exists in the URL
    if (url.includes('/public/')) {
      // Replace "public" with an empty string
      return url.replace('/public/', '/');
    }
    // If "public" is not found, return the original URL
    console.log("urllllllllllllllll",url)
    return url;
  }


  export function isDateGreaterThan(date1, date2) {
    // Parse the date strings using moment
    const momentDate1 = moment(date1, 'YYYY-MM-DD');
    const momentDate2 = moment(date2, 'YYYY-MM-DD');

    // Check if the first date is greater than the second date
    return momentDate1.isAfter(momentDate2);
  }


  export const isDateGreater = (startDate, endDate) => {
    // Replace these dates with your actual date values
    const start = moment(startDate);
    const end = moment(endDate);

    console.log("")

    if (start.isBefore(end)) {
      console.log('Date 1 is greater than Date 2');
       return true
    } 

    return false
  };



  export const countCompletedAndPending=(data)=> {
    let completedItems = 0;
    let pendingItems = 0;

    data.forEach(day => {
        day.data.forEach(item => {
          if(item?.title === "Do"){
            console.log("skip that title")
          }
          else if (item.status === "Completed") {
            completedItems++;
        } else if (item.status === "Pending") {
            pendingItems++;
        }
            // item.activities.forEach(activity => {
            //     if (activity.status === "Completed") {
            //         completedItems++;
            //     } else if (activity.status === "Pending") {
            //         pendingItems++;
            //     }
            // });
        });
    });

    return { completedItems, pendingItems };
}


// FOr subactivities
// export const filterCompletedTaskList = (data) => {
//   let completedTasks = [];
//   data.forEach(day => {
//     let completedActivities = [];
//     day.data.forEach(item => {
//       let completed = item.activities.filter(activity => activity.status === "Completed");
//       completedActivities = completedActivities.concat(completed);
//     });
//     if (completedActivities.length > 0) {
//       completedTasks.push({ day: day.day, activities: completedActivities });
//     }
//   });
//   return completedTasks;
// }

export const filterCompletedTaskList = (data) => {
  let completedTasks = [];
  data.forEach(day => {
    let completedActivities = [];
    day.data.forEach(item => {
      // Check if the main activity itself is completed
      if (item?.status === "Completed") {
        completedActivities.push(item);
      }
      // } else {
      //   // Check only main activities for completion
      //   let completed = item.activities.filter(activity => activity.status === "Completed");
      //   // If any main activity is completed, add it to the list
      //   if (completed.length > 0) {
      //     completedActivities.push({ ...item, activities: completed });
      //   }
      // }
    });
    if (completedActivities.length > 0) {
      completedTasks.push({ day: day.day, activities: completedActivities, totalDuration : day?.totalDuration ?? "" });
    }
  });
  return completedTasks;
}



export const setCookie = (key, value) => {
  try {
    Cookies.set(key,value, {
      path: "/",
      domain: COOKIE_DOMAIN,
    });
    return true;
  } catch (err) {
    console.log("error while setting cookie in domain", err);
    return false;
  }
};

export const getCookie = (key) => {
  try {
    const cookieDetails = Cookies.get(key);
    return cookieDetails;
  } catch (error) {
    console.log("error while getting cookie details", error);
    return false;
  }
};

export const removeCookie = (key) => {
  try {
    const cookieDetails = Cookies.remove(key);
    return cookieDetails;
  } catch (error) {
    console.log("error while getting cookie details", error);
    return false;
  }
};

export const userIsLoggedIn=()=>{
  try {
    const userID = getCookie("userId")
    const accessToken = window.localStorage.getItem("accessToken")
    if(userID && accessToken){
      return true;
    }
    return false;
  } catch (error) {
    console.log("error while checking if user is loggedIn or not", {error})
  }
}


export  const ProgressCalculator=async(taskViewByDayData,userId,scheduleId)=>{
  try {
    const {completedItems,pendingItems} = countCompletedAndPending(taskViewByDayData)
  
    const totalTasks = completedItems + pendingItems;
    const percentageCompleted = (completedItems / totalTasks) * 100;
    const finalPercentage  = percentageCompleted ?? 0
    const updateData = {
      userId:userId,
      idsList:[] ,// Assuming `userInfo` has `userId`
      scheduleId : scheduleId,
      progressCalculator:finalPercentage.toFixed(2)
      
    };
    const updateRes = await ScheduleApi.updateSchedulePlan(updateData)
    return finalPercentage

  } catch (error) {
      console.error("error while calculating progress bar percentage",error);
      return 0
  }
}


export function convertToHours(timeString) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
  // Calculate total hours
  const totalHours = hours + (minutes / 60) + (seconds / 3600);
  return totalHours;
}

export const scheduleDetailsOrganzier=(data,scheduleId)=>{

  // console.log("scheduleDetailsOrganzierscheduleDetailsOrganzier",filteredId)
  const schedule = data.find((item) => item?.scheduleId === scheduleId)
  if(schedule){    
    const dataToUpdate = {
      userId : schedule["userId"],
      schedulePlanDetails : schedule["schedulePlanDetails"],
      inputCriteria : schedule["inputCriteria"],
      preStudyRedinessScheduleDetails : schedule["preStudyRedinessScheduleDetails"],
      topicsList : schedule["topicsList"],
      eveningReview : schedule["eveningReview"],
      suggestedFinalReview : schedule["suggestedFinalReview"],
      excelUrl: schedule["excelUrl"],
      scheduleId : schedule["scheduleId"],
      everyThreeModule: schedule["everyThreeModule"],
      payment_status : schedule["payment_status"],
      trackingStarted : schedule["trackingStarted"],
      rebaseStarted: schedule["rebaseStarted"],
      rebaseHours: schedule["rebaseHours"],
      googleCalenderSSO:schedule['googleCalenderSSO']
  }
    return dataToUpdate
  }
  return false

}

export function updatePaymentStatus(scheduleId, data) {
  try {
      let updatedData = JSON.parse(JSON.stringify(data));
      for (let i = 0; i < updatedData.length; i++) {
          if (updatedData[i].scheduleId === scheduleId) {
              updatedData[i].payment_status = true;
              break;
          }
      }
      return updatedData;
  } catch (error) {
      console.log("Error while updating payment status:", error);
      return data;
  }
}


export function updateObjectById(array, scheduleId, updatedObject) {
  try {
      const newArray = array.slice(); // Clone the array
      const index = newArray.findIndex(obj => obj.scheduleId === scheduleId);
      if (index !== -1) {
          newArray[index] = { ...newArray[index], ...updatedObject };
      }
      return newArray;
  } catch (error) {
      console.log("error while updating object by id", error);
      return array; // Return original array in case of error
  }
}


export function sumUpTotalDuration(data) {
  try {
    let totalSeconds = 0;

    // Iterate over the data and sum up total duration
    data.forEach((item) => {
      const durationParts = item.totalDuration.split(":");
      const hours = parseInt(durationParts[0], 10) || 0;
      const minutes = parseInt(durationParts[1], 10) || 0;
      const seconds = parseInt(durationParts[2], 10) || 0;

      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    });

    // Convert total seconds to HH:MM:SS format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format the result
    const formattedResult = [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
    ].join(":");

    return formattedResult;
  } catch (error) {
    console.log("error while sum up total duration", error);
    return "00:00:00";
  }
}


export function changeTopic(input,) {
  // Split the input string into parts using underscore as delimiter
  var parts = input.split('_');
 // Extract the topic number
  var topicNumber = parseInt(parts[2]);
  // Increment the topic number
  topicNumber++;
  // Replace the third part of the input with the new topic number
  parts[2] = topicNumber.toString();
  // Join the parts back together with underscores
  var result = parts.join('_');
  // Return the modified string
  return result;
}


export function convertToHHMMSS(time) {
  var hours, minutes, seconds;

  // Assuming input is in hours
  hours = Math.floor(time);
  minutes = Math.floor((time - hours) * 60);
  seconds = Math.floor(((time - hours) * 60 - minutes) * 60);

  // Format the output as "HH:MM:SS"
  var HH = ("0" + hours).slice(-2);
  var MM = ("0" + minutes).slice(-2);
  var SS = ("0" + seconds).slice(-2);

  return HH + ":" + MM + ":" + SS;
}





function extractLinks(description) {
  // Regular expression to find URLs within the description
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // Check if description is not null or undefined
  if (!description) return [];
  // Find all matches
  const matches = description.match(urlRegex);
  return matches || [];
}

export function renderDescriptionWithLink(description) {
  // Check if description is not null or undefined
  if (!description) return "";
  // Extract links from the description
  const links = extractLinks(description);
  // Replace each link with clickable anchor tags
  let renderedDescription = description;
  links.forEach(link => {
    renderedDescription = renderedDescription.replace(
      link,
      `<a href="${link}" target="_blank" rel="noopener noreferrer">${link}</a>`
    );
  });
  return renderedDescription;
}
 
