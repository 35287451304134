import React, { useState } from 'react';
import './ChatInput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
const ChatInput = ({ onSend }) => {
  const [input, setInput] = useState('');

  const handleSendClick = () => {
    if (input.trim()) {
      onSend(input);
      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendClick();
    }
  };

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder="Type a message..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
      />
   <button className="btn btn-gradient" onClick={handleSendClick}>
  <FontAwesomeIcon icon={faPaperPlane} />
</button>
    </div>
  );
};

export default ChatInput;
