import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import * as AuthApi from "../../apis/authentication.api";
import { CRYPTO_SECRET_KEY } from "../../constants/envConstants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import "./styles.css"
const CryptoJS = require("crypto-js");

function PasswordReset() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()
    // Use URLSearchParams to parse the query parameters
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(password === newConfirmPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!passwordsMatch) {
            toast.error('Passwords do not match.');
            return;
        }

        const encryptedPassword = CryptoJS.AES.encrypt(password.trim(), CRYPTO_SECRET_KEY).toString();
        const result=await AuthApi.resetPassword({ password: encryptedPassword, token: token });
        if(result.status){
            toast.success(result.msg)
            navigate('/')
        }
        else{
            toast.error(result.msg)
        }
    };

  

 

    return (
        <form onSubmit={handleSubmit} className="formStyle-reset">
            <div style={{ position: 'relative', width: '100%' }}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="inputStyle-reset"
                    placeholder="Enter new password"
                />
                <span style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }} onClick={togglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </span>
            </div>
            <div style={{ position: 'relative', width: '100%' }}>
                <input
                    type={ 'password'}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                    className="inputStyle-reset"
                    placeholder="Confirm new password"
                />
                <span style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }} onClick={togglePasswordVisibility}>
                    
                </span>
            </div>
            {!passwordsMatch && <div className="mismatchStylereset">Passwords do not match</div>}
            <button type="submit" className="buttonStyle-reset">Reset Password</button>
        </form>
    );
}

export default PasswordReset;
