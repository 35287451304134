import React, { useEffect, useState,useRef } from "react";
import DayWiseTaskList from "./DayWiseTaskList";
import MyTooltip from "../../utils/MyTooltip/MyTooltip";
import { useLocation } from "react-router-dom";
import ExcelRenderer from "../ExcelRenderer/ExcelRenderer";
import TopicWiseTaskList from "./TopicWiseTaskList";
import { toast } from "react-toastify";
import CompletedTaskDetailsModal from "./CompletedTaskListModal";
import * as ScheduleApi from "../../apis/schedule.api"
import Loader from "../Loader";
import { convertToHHMMSS, convertToHours, filterCompletedTaskList, ProgressCalculator, scheduleDetailsOrganzier } from "../../utils";
import { DOWNLOAD_SCHEDULE, PERFORMANCE_MESSAGING } from "../../constants";
import { SERVER_URL } from "../../constants/envConstants";
import { removePublicFromURL } from "../../utils";
import CalendarView from "../CalendarView/CalendarView";
import { useDispatch, useSelector } from "react-redux";
import RebaseModal from "../RebaseModal/RebaseModal";
import { updateScheduleDetails } from "../../store/schedule/scheduleSlice";
import PaymentModal from '../Payments/PaymentModal'; // Make sure to create this component
import PaymentCheckoutForm from '../Payments/PaymentCheckoutForm'
import moment from "moment"
import { CircularProgress } from "@mui/material";
import { updateScheduleListDetails } from "../../store/scheduleList/scheduleListSlice";
import { STYLE_PLAN_TITLE } from "../../constants";
// import { IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as PaymentApi from  "../../apis/payment.api"

import SelectRescheduleDate from "./SelectRescheduleDate";
import RescheduleExamDate from "../RebaseModal/RescheduleExamDate";


const StudyPlanCheckBox = ({ tableData, setTableData, isPurchased, setIsPurchased, showPaymentModal, setShowPaymentModal }) => {
  const location = useLocation();
  // const [tableData,setTableData] = useState({ data:[], taskView: "taskViewByDay", loading: true , filePath :'' })
  const [showCheckoutForm, setShowCheckoutForm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [chargeAmount, setChargeAmount] = useState(0)
  const hasCompletedRef = useRef(false);
  const has1CompletedRef = useRef(false);
  const [taskViewByDayData, setTaskViewByDayData] = useState({
    data: [],
    progress: "0"
  })
  const {scheduleDetails}  = useSelector(state  => state.schedule)
  const [taskViewByTopicData, setTaskViewByTopicData] = useState({
    data: [],
    progress: "0"
  })
  const { trackingStarted } = useSelector(state => state.schedule)
   useEffect(()=>{
    retrieveUserAmount()
  },[])
  const retrieveUserAmount=async()=>{
    try {
     const data =await PaymentApi.chargeAmount();
     setChargeAmount(Number(data.amount))
     console.log("amountdata",data.amount)
     
                 
    } catch (error) {
        console.log("erroramount",error)
    }
  }
  // useEffect(()=>{
  //   retrieveUserDetails()
  // },[])
  // useEffect(() => {
  //   // Logic to handle content visibility after successful purchase
  //   if(localStorage.getItem('isPurchased')){
  //     const  checkIspurcahsed=localStorage.getItem('isPurchased')
  //     setIsPurchased(checkIspurcahsed)
  //      if (checkIspurcahsed) {
  //        setShowPaymentModal(false);
  //      }
  //   }

  // }, [isPurchased]);


// Test the function



  return (
    <>
    {console.log("tablehd",tableData?.data?.schedulePlanDetails)}
      <>
      <div className="alert alert-style" role="alert">
    <div className="row">
        <div className="col-9"style={{ paddingTop: '8px' ,textAlign: "left" }}>
            {STYLE_PLAN_TITLE}
        </div>
        <div className="col-md-3 text-md-end">
          <div style={{ fontSize: '10px',  }}> {/* Adjust the font size as needed */}
          <strong style={{ fontWeight: 500 }}>Exam Date :</strong> {scheduleDetails.schedulePlanDetails?.examDate}<br />
      <strong style={{ fontWeight: 500 ,right:'5px'}}>Exam Duration :</strong > {scheduleDetails.schedulePlanDetails?.examDuration}<br />
      <strong style={{ fontWeight: 500 }}>Study Hours/Day : </strong>{convertToHHMMSS((scheduleDetails.schedulePlanDetails?.studyHours ?? 0))}<br />
            
          </div>
        </div>
    </div>
</div>



        
        <div className="d-md-flex justify-content-around">
          <CheckBox text="Calendar View" disable={false} setTableData={setTableData} value="calendarView" tableData={tableData} trackingStarted={trackingStarted} />
          <CheckBox text="Task View by Day" disable={false} setTableData={setTableData} tableData={tableData} value="taskViewByDay" />

          <CheckBox text="Task View by Topic" disable={false} setTableData={setTableData} tableData={tableData} value="taskViewByTopic" />
        </div>
        {showPaymentModal && <PaymentModal setShowPaymentModal={setShowPaymentModal} showPaymentModal={showPaymentModal} showCheckoutForm={showCheckoutForm} setShowCheckoutForm={setShowCheckoutForm} taskView={tableData?.taskView} tableData={tableData} chargeAmount={chargeAmount}/>}
        {showCheckoutForm && <PaymentCheckoutForm setShowCheckoutForm={setShowCheckoutForm} showCheckoutForm={showCheckoutForm} setIsPurchased={setIsPurchased} setShowPaymentModal={setShowPaymentModal} showPaymentModal={showPaymentModal} setTableData={setTableData} tableData={tableData} taskView={tableData?.taskView}chargeAmount={chargeAmount} />}
        {tableData?.taskView == "taskViewByDay" && <DayWiseTaskList tableData={tableData} setTableData={setTableData} taskViewByDayData={taskViewByDayData} setTaskViewByDayData={setTaskViewByDayData} setShowPaymentModal={setShowPaymentModal} showPaymentModal={showPaymentModal} setIsPurchased={setIsPurchased} isPurchased={isPurchased} hasCompletedRef={hasCompletedRef}/>}
        {tableData?.taskView == "taskViewByTopic" && <TopicWiseTaskList tableData={tableData} setTableData={setTableData} taskViewByDayData={taskViewByTopicData} setTaskViewByDayData={setTaskViewByTopicData} setIsPurchased={setIsPurchased} isPurchased={isPurchased} setShowPaymentModal={setShowPaymentModal} trackingStarted={trackingStarted}setShowModal={setShowModal}has1CompletedRef={has1CompletedRef}/>}
        {tableData?.taskView == "calendarView" && <CalendarView tableData={tableData} setTableData={setTableData} taskViewByDayData={taskViewByDayData} setTaskViewByDayData={setTaskViewByDayData} setIsPurchased={setIsPurchased} isPurchased={isPurchased} setShowPaymentModal={setShowPaymentModal} />}

        <StudyPlanAction taskViewByDayData={taskViewByDayData} setTaskViewByDayData={setTaskViewByDayData} tableData={tableData} setTableData={setTableData} setShowModal={setShowModal} showModal={showModal} />
      </>
      {/* <PerformanceMessaging/> */}

    </>
  );
};

const CheckBox = ({ text, disable, setTableData, tableData, value, trackingStarted = false }) => {

  const { scheduleId } = useSelector(state => state.schedule)
  const updateTaskViewScreen = async (value) => {
    try {
      if (value == "calendarView") {
        if (trackingStarted) {
          console.log("trackingStarted",trackingStarted)
          setTableData((prevState) => ({ ...prevState, taskView: value }))
          return;
        } else {
          toast.error("Accessing the calendar view requires you to start tracking.");
          return;
        }
      }
      if (value != tableData.taskView && value != "calendarView") {
        setTableData((prevState) => ({ ...prevState, loading: true }))
        const userId = localStorage.getItem("userId")

        if (userId && value) {
          const data = {
            userId: userId,
            taskView: value,
            scheduleId: scheduleId
          }
          const response = await ScheduleApi.retrieveSchedulePlan(data);
          if (response.status) {
            const formattedData = JSON.parse(response?.json)
            const taskView = response?.taskView
            setTableData({ data: formattedData, taskView: taskView, loading: false, filePath: response?.filePath ?? "" })
          }
        }

        setTableData((prevState) => ({ ...prevState, loading: false }))
      }
    } catch (error) {
      console.log("error while selecting checkbox", error);
      setTableData((prevState) => ({ ...prevState, loading: false }))

    }
  }

  return (
    <div className="form-check d-md-flex justify-content-center" onClick={() => updateTaskViewScreen(value)} >
      
      <input
        className="form-check-input checkbox-style mx-1"
        style={{ cursor: 'pointer' }}
        type="checkbox"
        checked={tableData?.taskView === value}
        // disabled={disable}
        //onChange={() => updateTaskViewScreen(value)}
      />
      <label className="form-check-label label-style" style={{ cursor: 'pointer' }}>{text}</label>
    </div>
  );
};

const StudyPlanAction = ({ taskViewByDayData, setTaskViewByDayData, tableData, setTableData,setShowModal,showModal }) => {

  const [completedData, setCompletedData] = useState([])
  const [openCompleteListModal, setOpenCompleteListModal] = useState(false)
  const { userId, scheduleId } = useSelector((state) => state.schedule);
  const [rebaseModal, setRebaseModal] = useState(false)
  const [rebaseRescheduleModal, setRebaseRescheduleModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [rebaseHours, setRebaseHours] = useState('')
  
  const { trackingStarted } = useSelector(state => state.schedule)

  const CompletedTaskList = () => {
    if (taskViewByDayData?.data) {
      const completedStudyPlan = filterCompletedTaskList(taskViewByDayData?.data)
      if (completedStudyPlan.length > 0) {
        setCompletedData(completedStudyPlan)
        setOpenCompleteListModal(true)
      } else {
        toast.error("There are no tasks marked as completed at the moment.")
      }
    }
  }

  function noPedingTaskAvailable(data) {
    const today = new Date();
    const todayDateString = today.toLocaleDateString();
  
    for (const day of data) {
      for (const item of day.data) {
        const itemDate = new Date(item.date);
        const itemDateString = itemDate.toLocaleDateString();
        console.log("noPedingTaskAvailablenoPedingTaskAvailable",itemDateString,todayDateString,itemDateString < todayDateString,item.status)
        if (itemDateString < todayDateString && item.status === "Pending") {
          console.log("noPedingTaskAvailablenoPedingTaskAvailable 1",itemDateString,todayDateString,itemDateString < todayDateString,item.status)
          return false; // Exit the function immediately if condition is met
        }
        if(itemDateString>= todayDateString && item.status === "Pending"){
          return true;
        }
      }
    }
  
    return true; // If no pending tasks found, return true
  }
  

  function checkPendingTasks(data) {
    console.log("JSdsgggggggggggggggggg", JSON.stringify(data))
    const currentDate = moment();
    for (const item in data) {
      const dayDate = data[item]?.data[0]?.date

      if(dayDate) {
        const updaedDate = moment(dayDate, 'MM/DD/YYYY');
      console.log("updaedDateupdaedDateupdaedDateupdaedDate",{updaedDate, res: updaedDate.isBefore(currentDate, 'day')})

        if (updaedDate.isBefore(currentDate, 'day')) {
          return false
        }
      }
    }
    return true;
  }

  // Example usage

  const rebaseTask = async () => {
    try {
      // setLoader(true)
      if (!(taskViewByDayData?.data[0]?.data[0].date)) {
        toast.error("Tracking has not yet started. Rebase is only possible once tracking begins.");
        setLoader(false)
        return;
      }

      const havePending = checkPendingTasks(taskViewByDayData?.data);
      if (havePending) {
        toast.error("There are no tasks carried over from previous days, therefore rebase isn't applicable.");
        setLoader(false)
        return;
      }

      const havePreviousPendingTask = noPedingTaskAvailable(taskViewByDayData?.data)
      console.log("noPedingTaskAvailable havePreviousPendingTaskhavePreviousPendingTask",havePreviousPendingTask)
      if(havePreviousPendingTask){
        toast.error("There are no tasks carried over from previous days, therefore rebase isn't applicable.");
        setLoader(false)
        return;
      }

      const calculateProgress = ProgressCalculator(taskViewByDayData?.data);
      if (calculateProgress == 100) {
        toast.error("All tasks have been completed, so there's nothing to rebase.");
        setLoader(false)
        return;
      }

      const rebaseHrRes = await ScheduleApi.findRebaseHours({ userId: userId, scheduleId: scheduleId });
      console.log("rebaseHrRes.totalDuration",convertToHours(rebaseHrRes.totalDuration))
      if (rebaseHrRes.status && convertToHours(rebaseHrRes.totalDuration)<24) {
        console.log("dgfghow")
        setLoader(false)
        setRebaseModal(true);
        setRebaseHours(rebaseHrRes.totalDuration)
        return;
      }
      if (rebaseHrRes.status && convertToHours(rebaseHrRes.totalDuration)>=24) {
        setLoader(false)
        setRebaseRescheduleModal(true);
        setRebaseHours(rebaseHrRes.totalDuration)
        return;
      }
      toast.error("Rebase not valid in this schedule.");
      setLoader(false)
    } catch (error) {
      console.log("error while finding rebase hours", error);
      toast.error("Error encountered while performing rebase operation. Kindly refresh the page and try again.");
      setLoader(false)
      return false;
    }
  };

  const startTracking = () => {
    console.log("trackingStartedtrackingStarted", trackingStarted)
    if (!trackingStarted) {
      setShowModal(true);
    } else {
      toast.error("Tracking is already started !!")
    }
  };
  return (
    <>
      <div className="row">

        <div className="col-12 bottom-btn-outer p-4 mt-4">
          <div className="row">
            <div className="col-md-3 bottom-btn">
              <button
                type="button"
                className="btn btn-warning homepage-btn-style study-plan-action-style"

                // disabled={trackingStarted}
                onClick={() => { startTracking() }}
              >
                Start Tracking
                <PlayCircleOutlineIcon style={{ color: trackingStarted ? 'green' : 'inherit' }} />
              </button>

            </div>

            <div className="col-md-3 bottom-btn">

              {tableData.taskView == "taskViewByDay" &&
                <button
                  type="button"
                  className="btn btn-warning homepage-btn-style study-plan-action-style"

                  onClick={() => rebaseTask()}
                  disabled={loader}
                >
                  {loader ? <Loader show={loader} /> : "Rebaseline"}
                  <RestartAltIcon />
                </button>
              }
            </div>
            <div className="col-md-3 bottom-btn">
              {/* <MyTooltip> */}
              {taskViewByDayData?.data ?
                <button
                  type="button"
                  className="btn btn-warning homepage-btn-style study-plan-action-style"
                  onClick={CompletedTaskList}
                >
                  See Completed Tasks
                  <CheckCircleOutlineIcon />
                </button> :
                <MyTooltip>
                  <button
                    type="button"
                    className="btn btn-warning homepage-btn-style study-plan-action-style"
                    onClick={CompletedTaskList}
                  >
                    See Completed Tasks
                    <CheckCircleOutlineIcon />
                  </button>
                </MyTooltip>
              }
              {/* <MyTooltip> */}
            </div>
            <div className="col-md-3   bottom-btn">
              <PrintSchedule tableData={tableData} />
            </div>
          </div>
        </div>

      </div>
      <SelectRescheduleDate showModal={showModal} setShowModal={setShowModal} setTableData={setTableData} taskViewByDayData={taskViewByDayData} table={taskViewByDayData.data} />
      <CompletedTaskDetailsModal completedData={completedData} openCompleteListModal={openCompleteListModal} setOpenCompleteListModal={setOpenCompleteListModal} />
      {rebaseHours && rebaseRescheduleModal && <RescheduleExamDate rebaseRescheduleModal={rebaseRescheduleModal} setRebaseRescheduleModal={setRebaseRescheduleModal} rebaseHours={rebaseHours} setTaskViewByDayData={setTaskViewByDayData} tableData={tableData} />}
      {rebaseHours && rebaseModal && <RebaseModal rebaseModal={rebaseModal} setRebaseModal={setRebaseHours} rebaseHours={rebaseHours} setTaskViewByDayData={setTaskViewByDayData} tableData={tableData} />}
      <div className="d-flex justify-content-around mt-4">


        {/* </MyTooltip> */}
        {/* <MyTooltip>
        <button
          type="button"
          className="btn btn-warning homepage-btn-style study-plan-action-style"
          // style={{ backgroundColor: "#1155cc", color: "white" }}
        >
          Notification Preferences
        </button>
      </MyTooltip> */}
      </div>
    </>
  );
};

export default StudyPlanCheckBox;



const PerformanceMessaging = () => {
  return <>
    <MyTooltip>
      <div className="alert alert-style d-flex justify-content-center m-3" role="alert" style={{ backgroundColor: "#c9daf8" }} >
        {PERFORMANCE_MESSAGING}
      </div>
    </MyTooltip>
  </>
}


const PrintSchedule = ({ tableData }) => {
  const { scheduleId, userId, isPurchased } = useSelector(state => state.schedule)
  const [loader, setLoader] = useState(false)


  const generateExcel = async () => {
    console.log("generateExcel11111")
   
    try {
      if (!isPurchased) {
        toast.error("Complete your purchase before checking out the full schedule.");
        return;
      }
      if (tableData?.filePath) {
        console.log("generateExcel111112")
        const url = `${SERVER_URL}/${tableData?.filePath}`;
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${userId}-${scheduleId}.xlsx`); // Specify file name here

        document.body.appendChild(link);
        link.click();

        link.remove();
        return;
      } else {
        setLoader(true);
        if (scheduleId && userId) {
          console.log("generateExcel1111123")
          const response = await ScheduleApi.generateExcelSheet({ userId, scheduleId });
          if (response?.status && response?.excelUrl) {
            const url = removePublicFromURL(`${SERVER_URL}/${response?.excelUrl}`);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${userId - scheduleId}.xlsx`); // Specify file name here

            document.body.appendChild(link);
            link.click();

            link.remove();
            setLoader(false);
          } else {
            toast.error("Encountered an error while exporting Schedule. Please refresh the page and retry.");
            setLoader(false);
            return false;
          }
        } else {
          toast.error("Export schedule encountered an issue with an invalid schedule or user ID. Kindly reload the page and try again.");
          setLoader(false);
          return false;
        }
      }
    } catch (error) {
      console.error("Error while generating Excel:", error);
      toast.error("Export schedule encountered an issue. Kindly reload the page and try again.");
      setLoader(false);
      return false;
    }
  };


  // return <>
  //   <div className="float-right text-right">
  //     {loader && <CircularProgress style={{ marginRight: "2px" }} />}
  //     {/* <div className="m-0 text-center print-inner-block homepage-btn-style" onClick={() => generateExcel()} >
  //       {DOWNLOAD_SCHEDULE}

  //       <DownloadIcon />

  //     </div> */}
  //     <
  //               <button
  //                 type="button"
  //                 className="btn btn-warning homepage-btn-style study-plan-action-style"
  //                 onClick={() => generateExcel()}
  //               >
  //                 See Completed Tasks
  //                 <CheckCircleOutlineIcon />
  //               </button> 

  //   </div>
  // </>
  return(<>
    <div className="float-right text-right">
      {loader && <CircularProgress style={{ marginRight: "2px" }} />}
      {/* <div className="m-0 text-center print-inner-block homepage-btn-style" onClick={() => generateExcel()} >
        {DOWNLOAD_SCHEDULE}

        <DownloadIcon />

      </div> */}
      
                <button
                  type="button"
                  className="btn btn-warning homepage-btn-style study-plan-action-style pad-btn"
                  onClick={() => generateExcel()}
                >
                  {DOWNLOAD_SCHEDULE}
                  <DownloadIcon />
                </button> 

    </div>
  
  </>)
}
