import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Loader from "../Loader";
import * as ScheduleApi from "../../apis/schedule.api";
import Alert from "@mui/material/Alert";
import { COMPLETE_PREVIOUS_TASKS } from "../../constants";
import "./styles.css"
import { useDispatch, useSelector } from "react-redux";
import { changeTopic, updateObjectById } from "../../utils";
import { updateScheduleListDetails } from "../../store/scheduleList/scheduleListSlice";
import { updateScheduleDetails } from "../../store/schedule/scheduleSlice";
import { useNavigate } from "react-router-dom";

export default function TaskListDetails({
  taskList,
  setTableData,
  tableData,
  setOpenTaskDetails,
  taskView,
  setShowPaymentModal
}) {

  const [completedItems, setCompletedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previousTasksCompleted, setPreviousTasksCompleted] = useState({
    previousCompleted: true,
    checkBoxShow: false,
    taskViewData: []
  });

  const [completedItemIds, setCompletedItemIds] = useState([])
  const { userId } = useSelector((state) => state.user)
  const { scheduleId,isPurchased } = useSelector((state) => state.schedule)
  const { scheduleListDetails } = useSelector((state) => state.scheduleList)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  useEffect(() => {
    if (taskList) {
      previousTaskCompleted();
    }
  }, [taskList]);

  function updateStatusToCompleted(data) {
    const statusToUpdate = data.status == "Completed" ? "Pending" : "Completed";
    data.status = statusToUpdate
    // console.log("datadatadatadatadatadatadatadata",JSON.stringify(data))
    // // Iterate through activities array
    // data?.forEach((activity) => {
    //   // Update status to "Completed" if it's currently "InProgress" or vice versa
    //   console.log("statusToUpdate", statusToUpdate); // Log the updated status
    //   activity.status = statusToUpdate; // Update the status of the current activity
    // });
    return data;
  }

  const markTaskCompleted = (learningItem) => {
    const id = learningItem["id"];
    const status = learningItem["status"];
    const type = learningItem["type"]
    // Check if the id exists in completedItemIds
    console.log("completedItemIds",completedItemIds)
    const index = completedItemIds.findIndex(item => item.id === id);
    console.log("indextobe",index)

    if (index !== -1) {
      console.log("ALREDYCOMPLETED")
      const updatedIds = [...completedItemIds];
      updatedIds.splice(index, 1);
      setCompletedItemIds(updatedIds);

    //  toast.error('cant do that')
    } else {
      console.log("ALREDYCOMPLETED1")
      const updatedItems = [...completedItemIds];
      if (status === "Completed") {
        toast.error('cant do that')
      } else if (status === "Pending") {
        updatedItems.push({ id: id, status: "Completed", type: type });
      }
      setCompletedItemIds(updatedItems);
    }
  };

  



  const submitData = async () => {
   
    if (completedItemIds.length > 0 && userId) {
      setLoading(true)
      const data = {
        userId: userId,
        idsList: completedItemIds,
        scheduleId: scheduleId,
        taskView: taskView,

      }

      const updateRes = await ScheduleApi.updateSchedulePlan(data);
      console.log("submitDatasubmitDatasubmitDatasubmitDatasubmitData", updateRes)
      if (updateRes.status) {

        toast.success("Task status updated successfully!")
        const formattedData = JSON.parse(updateRes?.json)
        let scheduleData = JSON.parse(updateRes?.data)
        const taskView = updateRes?.taskView
        let scheduleList = scheduleListDetails
        console.log("formattedDataformattedDataformattedDataformattedData", updateRes)
        const updatedData = updateObjectById(scheduleList, scheduleId, scheduleData)
        dispatch(updateScheduleListDetails(updatedData))
        dispatch(updateScheduleDetails(updateRes))
        // setTableData({data : formattedData, taskView : taskView, loading: false,filePath: updateRes?.filePath ?? ""})
        setTableData(prevState => ({
          ...prevState, // This will keep all the previous state values including taskView
          data: formattedData,
          loading: false,
          filePath: updateRes?.filePath ?? ""
        }));
        setOpenTaskDetails(false)
      } else {
        toast.error("Encountered an error while marking the task as completed. Please reload the page and try once more.")
      }
      setLoading(false)
    } else {
      setOpenTaskDetails(false)

    }

  }
  console.log("taskList", taskList)
  const isTaskCompleted = (learningItem) => {
    for (let i = 0; i < learningItem?.activities?.length; i++) {
      if (learningItem.activities[i].status === "Completed") {
         return true; 
      }
    }
    return false;
  };
  const isCompletedTask = (learningItem) => {
    // for (let i = 0; i < learningItem?.activities?.length; i++) {
    //   if (learningItem.activities[i].status === "Completed") {
    //      return true; 
    //   }
    // }
    return learningItem.status == "Completed";
  };

  function checkAllCompleted(data) {
    return data.every(dayData => {
      if (dayData.title === "Do") {
        return true; // Skip status check for "Do" items
      }
      return dayData?.status === "Completed";
    });
  }
  const previousTaskCompleted = () => {
    if (taskList?.itemNumber == 0) {
      setPreviousTasksCompleted((prevState) => ({ ...prevState, previousCompleted: true }))
      return;
    }
    if (taskList?.data && taskList?.itemNumber > 0) {
      const dataElement = tableData?.data?.[Number(taskList?.itemNumber) - 1];
      if (dataElement["data"]) {
        const result = checkAllCompleted(dataElement["data"]);
        if (result) {
          setPreviousTasksCompleted((prevState) => ({ ...prevState, previousCompleted: true }))
          return;

        }
      }
    }
    setPreviousTasksCompleted((prevState) => ({ ...prevState, previousCompleted: false, checkBoxShow: true }))
    return;

  };

  const updateAlert = (value) => {
    setPreviousTasksCompleted((prevState) => ({
      ...prevState,
      checkBoxShow: value,
    }));
  };


  const isCheckboxDisabled = () => {
    return !previousTasksCompleted.previousCompleted;

  };

  const navigateToTaskDetails=(learningItem)=>{
     navigate(`/schedule-details`, { state: {learningItem ,taskView, scheduleId } })
  }

  const haveContent=()=>{
    setShowPaymentModal(true)
    setOpenTaskDetails(false)
    // console.log("learningItemlearningItem",learningItem)
    // const contentDetails = learningItem?.details
    // const haveActivities = contentDetails?.activities
    // const haveDescription  = contentDetails?.description
    // const rootTitle = learningItem?.title
    // if(isPurchased){
    //   if(rootTitle && (haveActivities || haveDescription)){
    //       return true
    //   }
    //   if(contentDetails?.title && (haveActivities || haveDescription)){
    //       return true
    //   }
    //   if(contentDetails?.Activity && (haveActivities || haveDescription)){
    //       return true
    //   }
    // }
    // return false
  }

  return (
    <>
      <Loader show={loading} />
      {previousTasksCompleted.checkBoxShow && (
        <Alert
          className="my-3"
          style={{ fontSize: "16px" }}
          variant="outlined"
          severity="warning"
        >
          {COMPLETE_PREVIOUS_TASKS}
        </Alert>
      )}
      <TableContainer
        component={Paper}
        id="task-view-by-day-table-css"
        style={{ maxHeight: "500px", overflowY: "scroll" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Task</TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                Duration
              </TableCell>

              <TableCell align="left" style={{ fontWeight: "bold" }}>
                Complete

              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {taskList?.data?.data?.map((learningItem, index) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}


              >
                {[
                  <TableCell key="title">
                    {learningItem.title}
                    {<span onClick={() =>isPurchased? navigateToTaskDetails(learningItem):haveContent()} ><p className="learn-more-style">{"  Learn More..."}</p></span>}
                  </TableCell>,
                  <TableCell key="duration"   onClick={(e) => {
                    // Logic to handle row click, excluding clicks on checkboxes or labels
                    if (e.target.type !== 'checkbox' && e.target.nodeName !== 'LABEL') {
                      const checkbox = document.getElementById(`checkbox-${index}`);
                      if (checkbox && !isCheckboxDisabled()&& !isCompletedTask(learningItem) ) {
                        checkbox.checked = !checkbox.checked;
                        markTaskCompleted(learningItem);
                      }
                    }
                  }}>
                    {learningItem.totalDuration && learningItem.totalDuration !== "00:00:00" ?
                      learningItem.totalDuration : " "}
                  </TableCell>,

                  <TableCell key="checkbox"   onClick={(e) => {
                    // Logic to handle row click, excluding clicks on checkboxes or labels
                    if (e.target.type !== 'checkbox' && e.target.nodeName !== 'LABEL') {
                      const checkbox = document.getElementById(`checkbox-${index}`);
                      if (checkbox && !isCheckboxDisabled()&& !isCompletedTask(learningItem)) {
                        checkbox.checked = !checkbox.checked;
                        markTaskCompleted(learningItem);
                      }
                    }
                  }}>
                    {learningItem?.title != "Do" &&
                    <div style={{ cursor: isCheckboxDisabled()  || isCompletedTask(learningItem)? "not-allowed" : "pointer" }}>

                      {
                        <>
                            {isCheckboxDisabled() || isCompletedTask(learningItem) ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`checkbox-${index}`} // Ensuring the ID is unique
                                defaultChecked={isCompletedTask(learningItem)}
                                style={{ border: "1px solid black" }}
                                disabled
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={isCompletedTask(learningItem)}
                                defaultChecked={isCompletedTask(learningItem)}
                                id={`checkbox-${index}`} // Ensuring the ID is unique
                                style={{ border: "1px solid black" }}
                                //disabled={isCompletedTask(learningItem)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  if(isCompletedTask(learningItem)){
                                    toast.error("dvfx")
                                  } // Preventing TableRow's onClick when the checkbox is directly clicked
                                  else{
                                    markTaskCompleted(learningItem);
                                  }
                                   // Call markTaskCompleted for learningItem here if toggling the checkbox should also mark the task as completed
                                }}
                              />
                            )}
                          </>
                        
                      }


                    </div>}
                  </TableCell>
                ].map((cell, cellIndex) => (
                  isCheckboxDisabled() || isCompletedTask(learningItem)? (
                    <Tooltip
                      key={cellIndex}
                      title={isCompletedTask(learningItem)?'':"Please Complete Your Previous Tasks First"}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: { fontSize: "16px", fontWeight: "700" },
                        },
                      }}
                    >
                      {cell}
                    </Tooltip>
                  ) : cell
                ))}
              </TableRow>
            ))}


            {taskList?.data?.data[0]?.eveningReview &&
              <TableRow key="eveningReview">
                <TableCell colSpan={3}>
                {"Evening Review"}
         
                 
                  <span onClick={() =>isPurchased? navigateToTaskDetails(taskList?.data?.data[0]?.eveningReview):haveContent()} ><p className="learn-more-style">{"  Learn More..."}</p></span>
                
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center align-items-center">
        <Button
          variant="contained"
          id="submit-date-css"
          className="mt-3"
          onClick={() => submitData()}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
