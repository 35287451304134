import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: '#ffff',
  zIndex: theme.zIndex.drawer + 1,
  backdropFilter: 'blur(14px)', // You can adjust the blur value as needed
}));

export default function Loader({ show }) {
  return (
    <StyledBackdrop open={show}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}
