import React, { useCallback, useState } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import * as AuthApi from "../../apis/authentication.api"
import { toast } from "react-toastify";
import { setCookie } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../store/user/userSlice";
import RegistrationForm from "../RegistrationFrom/RegistrationForm";
import { Button } from "@mui/material";
import GoogleIcon  from "../../assets/images/search.png"

const GoogleSignIn = ({ setOpenSignUpModal,openSignUpModal,setgoToDashboard,component,formData}) => {

   const navigate = useNavigate()
   const dispatch = useDispatch()

   const [showRegistrationForm,setShowRegistrationFrom] = useState(false)

    const loginWithGoogle = useCallback(
        useGoogleLogin({
          onSuccess: async (response) => {
            await retrieveOrCreateUser(response)
          },
          onError: (error) => console.log("errorResponse",error),
        }),
        []
      );

    // const login=()=>{
    //   setShowRegistrationFrom(true)
    // }

    const retrieveOrCreateUser=async(response)=>{
        try {
            const authRes = await AuthApi.googleLogIn({accessToken: response?.access_token,userType : "user"});
            if(authRes?.status && authRes?.user?.userId){
              // showToastMsg(authRes)
              localStorage.setItem("accessToken", authRes?.token)
              localStorage.setItem("userId",authRes?.user?.userId)
              setCookie("userId",authRes?.user?.userId)
              dispatch(updateUserDetails(authRes));


              if(authRes?.haveSchedule && component == "scheduleDetails"){
                setgoToDashboard(true)
                setOpenSignUpModal(false)
              }

              if(formData){
                // navigate("/proficiency-setup", { state: formData })
              }
              setOpenSignUpModal(false)

            }else{
              console.log("userRes.msguserRes.msg",authRes.msg)
              toast.error(authRes.msg)
            }
        } catch (error) {
            console.log("error while retrieving or creating a user",{error:error, response:response});
            toast.error("Having trouble logging in with Google. Please refresh and retry.");
            setOpenSignUpModal(false)

        }
    }

    // const setImportantDetails=(response)=>{
    //     const userId = response?.user?.userId
    //     const accessToken  = response?.token
    //     if(userId){
    //         setCookie("userId",userId)
    //         window.localStorage.setItem("userId",userId)
    //     }
    //     if(accessToken){
    //        window.localStorage.setItem("accessToken",accessToken)
    //     }
    // }

    // const showToastMsg=(response)=>{
    //     if(response?.newUser == false){
    //         toast.success("User loggedIn successfully !!")
    //     }
    //     if(response.newUser){
    //         toast.success("User successfully created")
    //     }
    // }
  return (
    <>
      <RegistrationForm
        showRegistrationForm={showRegistrationForm}
        setShowRegistrationFrom={setShowRegistrationFrom}
      />
      <Button type="submit" onClick={() => loginWithGoogle()}  fullWidth variant="contained" sx={{ mt: 3, mb: 2 , backgroundColor : "white",color:"black" }}>
         <span><img src={GoogleIcon} id="google-icon-style" /></span>Continue with Google
      </Button>
    </>
  );
};

export default GoogleSignIn;
