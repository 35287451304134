/**
 * Component will show schedule data in form of table
 */

import React, { useState } from "react";
import { classes } from "./style";
function StudyTimeArea(){

  const [studyPlanData,setStudyPlanData] = useState({});
  
  
  React.useEffect(() => {    
    let sessionData = sessionStorage.getItem("studyPlanFormData");
    sessionData = sessionData ? JSON.parse(sessionData) : {}
    setStudyPlanData(sessionData)
  }, []);

  return (
    <div className="col-12 table-responsive">
      <table className="table table-bordered" style={classes.rescheduleContentTable}>
        <tr>
          <th style={classes.rescheduleContentTableCell}>Exam Date</th>
          <th style={classes.rescheduleContentTableCell}>Exam Duration</th>
          <th style={classes.rescheduleContentTableCell}>Study Hours</th>
        </tr>
        <tr>
          <td style={classes.rescheduleContentTableCell}>{studyPlanData.examDate}</td>
          <td style={classes.rescheduleContentTableCell}>{studyPlanData.examDuration}</td>                        
          <td style={classes.rescheduleContentTableCell}>{studyPlanData.studyHours}</td>
        </tr>
      </table>
    </div>
  );
}

export default StudyTimeArea;