import React from "react";
import StudyPlanCheckBox from "./StudyPlanCheckBox";

const StudyPlanDetails = ({tableData,setTableData,isPurchased,setIsPurchased,showPaymentModal,setShowPaymentModal}) => {
  return (
    <div className="col-md-8 container mt-4 px-3">
      <div className="study-plan-details p-4 ">        
        <StudyPlanCheckBox tableData={tableData} setTableData={setTableData}  isPurchased={isPurchased} setIsPurchased={setIsPurchased} showPaymentModal={showPaymentModal} setShowPaymentModal={setShowPaymentModal} />
      </div>
    </div>
  );
};

export default StudyPlanDetails;
