import moment from "moment";

// This function will transform day data according to frontend requirements 
function transformData(inputData) {
    const transformedData = [];

    for (const day in inputData) {
        const data = inputData[day]
        transformedData.push({
            day : day,
            data : data
        })
    }

    return transformedData;
}

// This function will organize task view by topics data into task view by day 
export function topicByDayRendrer(data,rebaseHours=false){
  try {
      console.log("finalRebaseHoursfinalRebaseHoursfinalRebaseHours",rebaseHours)
      const  {topicsList,schedulePlanDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview} = data
      const studyHourDetails = rebaseHours == false ? schedulePlanDetails["studyHours"] :rebaseHours
      // console.log("TESTTTTTTTTTTTTTTTTTTTTTTTTTTT",{topicsList,schedulePlanDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview})
      const organizeData = renderDayWiseSchedule(topicsList,studyHourDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview,schedulePlanDetails)
      console.log("organizeDataorganizeDataorganizeData",organizeData)
      const transfromedOrganizedData = transformData(organizeData)
      // console.log("transfromedOrganizedData",JSON.stringify(transfromedOrganizedData))
      return transfromedOrganizedData ?? [];
      
  } catch (error) {
      console.error("error while organizing day by topic", error);
      return {}
      
  }
}



// This function will organize task view by topics data into task view by day 
export function topicByDayOrganizer(data,rebaseHours=false){
    try {
        console.log("finalRebaseHoursfinalRebaseHoursfinalRebaseHours",rebaseHours)
        const  {topicsList,schedulePlanDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview} = data
        const studyHourDetails = rebaseHours == false ? schedulePlanDetails["studyHours"] :rebaseHours
        // console.log("TESTTTTTTTTTTTTTTTTTTTTTTTTTTT",{topicsList,schedulePlanDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview})
        const organizeData = generateDayWiseSchedule(topicsList,studyHourDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview,schedulePlanDetails)
        console.log("organizeDataorganizeDataorganizeData",organizeData)
        const transfromedOrganizedData = transformData(organizeData)
        // console.log("transfromedOrganizedData",JSON.stringify(transfromedOrganizedData))
        return transfromedOrganizedData ?? [];
        
    } catch (error) {
        console.error("error while organizing day by topic", error);
        return {}
        
    }
}

export function topicOrganizer(data){
  try {
      const  {topicsList,schedulePlanDetails,preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview} = data

      const organizeData = generateTopicWiseSchedule(topicsList,schedulePlanDetails["studyHours"],preStudyRedinessScheduleDetails,everyThreeModule,suggestedFinalReview,eveningReview)
      // console.log("organizeData",organizeData)
      const transfromedOrganizedData = transformData(organizeData)
      // console.log("transfromedOrganizedData",JSON.stringify(transfromedOrganizedData))
      return transfromedOrganizedData ?? [];
      
  } catch (error) {
      console.error("error while organizing day by topic", error);
      return {}
      
  }
}
/**
 * 
 * This function is responsible for tansforming below data : 
 *  - Pre study rediness schedule
 *  -  Topics List
 *  - Evening review
 *  - Every three module
 *  - Final Schedule details
 * 
 * into task View by Day Data
 **/ 

const compareTwoDates = (dateOne, dateTwo) => {
  const d1 = new Date(dateOne);
  const d2 = new Date(dateTwo);

  // Extracting year, month, and day from each date
  const year1 = d1.getFullYear();
  const month1 = d1.getMonth();
  const day1 = d1.getDate();
  console.log("compareTwoDates d1",{year1, month1,day1})

  const year2 = d2.getFullYear();
  const month2 = d2.getMonth();
  const day2 = d2.getDate();
  console.log("compareTwoDates d2",{year2, month2,day2})


  // Comparing year, month, and day
  console.log("compareTwoDates",{dateOne, dateTwo})
  return !(year1 === year2 && month1 === month2 && day1 === day2);
};

function renderDayWiseSchedule(inputData, studyHours, preStudyRedinessSchedule,everyThreeModule,suggestedFinalReview,eveningReview,schedulePlanDetails) {
  const days = {};
  let currentDay = 1;
  let everyThreeModuleIndex = 0;
  let previousActivityDate = null
  // Process pre-study readiness schedule
  const preStudyRedinessScheduleDetails = preStudyRedinessSchedule["Pre_Study_Readiness_Schedule"]["Activities"]
  if (preStudyRedinessScheduleDetails) {
    const preStudyActivities = preStudyRedinessScheduleDetails;

    preStudyActivities.forEach(activity => {
      if (activity.Time) {
        if(previousActivityDate == null || (previousActivityDate != activity?.date)){
              currentDay++;
            if (!days[`Day${currentDay}`]) {
              days[`Day${currentDay}`] = [];
            }

        }

        
        days[`Day${currentDay}`].push({
          title: activity.Activity,
          totalDuration: activity.Time,
          status: activity.status ?? "Pending",
          id : activity.id,
          type: activity.type,
          date : activity?.date  ?? "",
          details : activity
        });
        previousActivityDate = activity?.date
      }
    });
  }

  // Process study plan inputData
 
  for (let i = 0; i < inputData.length; i++) {
    const plan = inputData[i];
    for (const topicKey in plan) {
      const topic = plan[topicKey];

      for (const subtopicKey in topic) {
        const subtopic = topic[subtopicKey];
        if (subtopic.totalDuration && subtopic.totalDuration != undefined) {
          if(previousActivityDate == null || (previousActivityDate != subtopic?.date)){
                currentDay++;
    
              if (!days[`Day${currentDay}`]) {
                days[`Day${currentDay}`] = [];
              }
        }
          days[`Day${currentDay}`].push({
            title: subtopic.title,
            totalDuration: subtopic.totalDuration,
            activities: [subtopic],
            id : subtopic.id,
            status: subtopic?.status ?? "Pending",
            type: subtopic.type,
            date: subtopic?.date ?? " ",
            eveningReview: eveningReview['Evening_Review'],
            details : subtopic
          });

          previousActivityDate = subtopic?.date;
        }
      }
    }

    const everyThreeModuleDetails = everyThreeModule[everyThreeModuleIndex]['Every_Three_Topics_-_Take_a_baseline_Mock_Exam']
    if ((i + 1) % 3 == 0) {
        if(previousActivityDate == null || (previousActivityDate != everyThreeModuleDetails?.date)){
            currentDay++;
            
            if (!days[`Day${currentDay}`]) {
              days[`Day${currentDay}`] = [];
            }
        }
      // currentDay++;
      // currentDuration = studyHours * 60;

      everyThreeModuleIndex++
      days[`Day${currentDay}`].push({
        title: everyThreeModuleDetails.title,
        totalDuration: everyThreeModuleDetails.totalDuration,
        activities: [everyThreeModuleDetails.activities],
        status: everyThreeModuleDetails?.status ?? "Pending",
        type: everyThreeModuleDetails?.type,
        id : everyThreeModuleDetails.id,
        date : everyThreeModuleDetails?.date ?? " ",
        details : everyThreeModuleDetails
      });
      previousActivityDate=everyThreeModuleDetails?.date 
    }
  }
 
  if(previousActivityDate == null || (previousActivityDate != suggestedFinalReview["Review_Topic_Summary_Mind_Maps"]?.date)){
      currentDay++;
      if (!days[`Day${currentDay}`]) {
        days[`Day${currentDay}`] = [];
      }
  }


  // console.log("examDateexamDateexamDateexamDate",{examDate,totalDays,currentDay:currentDay+1})

  for (const activityTitle in suggestedFinalReview) {
    if(activityTitle !="TotalTime"){
      days[`Day${currentDay}`].push({
        title: activityTitle.replace(/_/g, ' '),
        totalDuration: suggestedFinalReview[activityTitle].Total,
        status: suggestedFinalReview[activityTitle].status ??"Pending",
        id : suggestedFinalReview[activityTitle].id,
        type :  suggestedFinalReview[activityTitle].type,
        date : suggestedFinalReview[activityTitle]?.date ?? " ",
        details : suggestedFinalReview[activityTitle]
      });
    }
  }

  return days;
}


function generateDayWiseSchedule(inputData, studyHours, preStudyRedinessSchedule,everyThreeModule,suggestedFinalReview,eveningReview,schedulePlanDetails) {
    const days = {};
    let currentDay = 1;
    let currentDuration = 0;
    let everyThreeModuleIndex = 0;
    const examDate = schedulePlanDetails["examDate"]
    console.log("examDatecheck",examDate,moment())
    const totalDays = moment(examDate).diff(moment(), 'days');
    let previousActivityDate = null
    // Process pre-study readiness schedule
    const preStudyRedinessScheduleDetails = preStudyRedinessSchedule["Pre_Study_Readiness_Schedule"]["Activities"]
    if (preStudyRedinessScheduleDetails) {
      const preStudyActivities = preStudyRedinessScheduleDetails;
  
      preStudyActivities.forEach(activity => {
        if (activity.Time) {
          const duration = parseDuration(activity.Time);
          if(previousActivityDate == null || (compareTwoDates(previousActivityDate,activity.date))){
              if ((currentDuration + duration) > studyHours * 60) {
                currentDay++;
                currentDuration = 0;
              }
      
              if (!days[`Day${currentDay}`]) {
                days[`Day${currentDay}`] = [];
              }

          }

          
          days[`Day${currentDay}`].push({
            title: activity.Activity,
            totalDuration: activity.Time,
            status: activity.status ?? "Pending",
            id : activity.id,
            type: activity.type,
            date : activity?.date  ?? "",
            details : activity
          });
  
          currentDuration += duration;
          previousActivityDate = activity?.date
        }
      });
    }
  
    // Process study plan inputData
   
    for (let i = 0; i < inputData.length; i++) {
      const plan = inputData[i];
      for (const topicKey in plan) {
        const topic = plan[topicKey];
  
        for (const subtopicKey in topic) {
          const subtopic = topic[subtopicKey];
          if (subtopic.totalDuration && subtopic.totalDuration != undefined) {
            const duration = parseDuration(subtopic.totalDuration);
            console.log("durationCount",duration,subtopic.date,previousActivityDate,compareTwoDates(previousActivityDate,subtopic.date),currentDuration + duration > studyHours * 60,currentDuration)
            if(previousActivityDate == null || (compareTwoDates(previousActivityDate,subtopic.date))){
              if((currentDay) <= totalDays){
                if (currentDuration + duration > studyHours * 60) {
                  currentDay++;
                  currentDuration = 0;
                }
      
                if (!days[`Day${currentDay}`]) {
                  days[`Day${currentDay}`] = [];
                }
              }
          }
            days[`Day${currentDay}`].push({
              title: subtopic.title,
              totalDuration: subtopic.totalDuration,
              activities: [subtopic],
              id : subtopic.id,
              status: subtopic?.status ?? "Pending",
              type: subtopic.type,
              date: subtopic?.date ?? " ",
              eveningReview: eveningReview['Evening_Review'],
              details : subtopic
            });
  
            currentDuration += duration;
            previousActivityDate = subtopic?.date;
          }
        }
      }

      console.log("everyThreeModuleIndexeveryThreeModuleIndex",everyThreeModuleIndex)
      const everyThreeModuleDetails = everyThreeModule[everyThreeModuleIndex]['Every_Three_Topics_-_Take_a_baseline_Mock_Exam']
      if ((i + 1) % 3 == 0) {
        const everyThreeModuleDuration = parseDuration(everyThreeModuleDetails.totalDuration)
        console.log("everyThreeModuleDurationeveryThreeModuleDurationeveryThreeModuleDuration",{everyThreeModuleDuration,currentDuration,studyHours})
        if((currentDay) <= totalDays){
          if(previousActivityDate == null || (compareTwoDates(previousActivityDate,everyThreeModuleDetails.date))){
            if ((currentDuration + everyThreeModuleDuration) > studyHours * 60) {
              currentDay++;
              currentDuration = 0;
              
              if (!days[`Day${currentDay}`]) {
                days[`Day${currentDay}`] = [];
              }
            }
          }
        }
        // currentDay++;
        // currentDuration = studyHours * 60;

        everyThreeModuleIndex++
        days[`Day${currentDay}`].push({
          title: everyThreeModuleDetails.title,
          totalDuration: everyThreeModuleDetails.totalDuration,
          activities: [everyThreeModuleDetails.activities],
          status: everyThreeModuleDetails?.status ?? "Pending",
          type: everyThreeModuleDetails?.type,
          id : everyThreeModuleDetails.id,
          date : everyThreeModuleDetails?.date ?? " ",
          details:everyThreeModuleDetails
        });
        currentDuration += everyThreeModuleDuration
        previousActivityDate=everyThreeModuleDetails?.date 
      }
    }
   
    if(previousActivityDate == null || (compareTwoDates(previousActivityDate,suggestedFinalReview["Review_Topic_Summary_Mind_Maps"]?.date))){
      if((currentDay) <= totalDays){
        currentDay++;
        if (!days[`Day${currentDay}`]) {
          days[`Day${currentDay}`] = [];
        }
      }
    }


    console.log("examDateexamDateexamDateexamDate",{examDate,totalDays,currentDay:currentDay+1})

    for (const activityTitle in suggestedFinalReview) {
      if(activityTitle !="TotalTime"){
        days[`Day${currentDay}`].push({
          title: activityTitle.replace(/_/g, ' '),
          totalDuration: suggestedFinalReview[activityTitle].Total,
          status: suggestedFinalReview[activityTitle].status ??"Pending",
          id : suggestedFinalReview[activityTitle].id,
          type :  suggestedFinalReview[activityTitle].type,
          date : suggestedFinalReview[activityTitle]?.date ?? " ",
          details : suggestedFinalReview[activityTitle]
        });
      }
    }
  
    return days;
  }



  // This function convert HH:MM:SS to hours 
  export function parseDuration(duration) {
    // console.log("reached",duration)
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  }
  
  function generateTopicWiseSchedule(inputData, studyHours, preStudyRedinessSchedule,everyThreeModule,suggestedFinalReview,eveningReview) {

    try {
      
      console.log("inputData, studyHours, preStudyRedinessSchedule,everyThreeModule,suggestedFinalReview,eveningReview",{inputData, studyHours, preStudyRedinessSchedule,everyThreeModule,suggestedFinalReview,eveningReview})
        const days = {};
        let everyThreeModuleIndex = 0;
        let currentDuration = 0;

      
        // Process pre-study readiness schedule
        const preStudyRedinessScheduleDetails = preStudyRedinessSchedule["Pre_Study_Readiness_Schedule"]["Activities"]
        if (preStudyRedinessScheduleDetails) {
          const preStudyActivities = preStudyRedinessScheduleDetails;
          days["Pre_Study_Readiness_Schedule"] = [];
          preStudyActivities.forEach(activity => {
            if (activity.Time) {
              const duration = parseDuration(activity.Time);
      
              // if (currentDuration + duration > studyHours * 60) {
              //   currentDay++;
              //   currentDuration = 0;
              // }
      
              
                
              
      
              days["Pre_Study_Readiness_Schedule"].push({
                title: activity.Activity,
                totalDuration: activity.Time,
                status: activity.status ?? "Pending",
                id : activity.id,
                type: activity.type,
                details: activity
              });
      
            // currentDuration += duration;
            }
          });
        }
      
        //Process study plan inputData
        for (let i = 0; i < inputData.length; i++) {
          const plan = inputData[i];
          for (const topicKey in plan) {
            const topic = plan[topicKey];
            let spaces = ' '.repeat(i)
            days[`${topicKey}${spaces}`] = [];
            for (const subtopicKey in topic) {
              const subtopic = topic[subtopicKey];
              if (subtopic.totalDuration && subtopic.totalDuration != undefined) {
                const duration = parseDuration(subtopic.totalDuration);
                
                // if (currentDuration + duration > studyHours * 60) {
                //   currentDay++;
                //   currentDuration = 0;
                // }
      
                // if (!days[`Day${currentDay}`]) {
                //   days[`Day${currentDay}`] = [];
                // }
      
                days[`${topicKey}${spaces}`].push({
                  title: subtopic.title,
                  totalDuration: subtopic.totalDuration,
                  activities: [subtopic],
                  id : subtopic.id,
                  status: subtopic?.status ?? "Pending",
                  type: subtopic.type,
                  eveningReview: eveningReview['Evening_Review'],
                  details:subtopic
                });
      
              
              }
            }
          }
          const everyThreeModuleDetails = everyThreeModule[everyThreeModuleIndex]['Every_Three_Topics_-_Take_a_baseline_Mock_Exam']
          // // console.log("everyThreeModuleDetails",everyThreeModuleDetails)
        
          if ((i + 1) % 3 == 0) {
            console.log("i",i)
            // currentDay++;
            // currentDuration = studyHours * 60;
            let spaces = ' '.repeat(i)
              days[`Every 3 modules${spaces}`] = [];
            
            days[`Every 3 modules${spaces}`].push({
              title: everyThreeModuleDetails.title,
              totalDuration: everyThreeModuleDetails.totalDuration,
              activities: [everyThreeModuleDetails.activities],
              status: everyThreeModuleDetails?.status ?? "Pending",
              type: everyThreeModuleDetails?.type,
              id : everyThreeModuleDetails.id,
              details : everyThreeModuleDetails
            });
            everyThreeModuleIndex ++
          }
        }
    
        // currentDay++;
      
          days["Suggested Final Review"] = [];
        
        for (const activityTitle in suggestedFinalReview) {
          if(activityTitle !="TotalTime"){
            days["Suggested Final Review"].push({
              title: activityTitle.replace(/_/g, ' '),
              totalDuration: suggestedFinalReview[activityTitle].Total,
              status: suggestedFinalReview[activityTitle].status ??"Pending",
              id : suggestedFinalReview[activityTitle].id,
              type :  suggestedFinalReview[activityTitle].type,
              details : suggestedFinalReview[activityTitle]
            });
          }
        }
      
        return days;
    } catch (error) {
       console.log("eeeeeeeeeeeeeeeeeeeeeeeeee", error)
    }
   
  }