export const classes = {
  continueBtn: {
    width: "30%",
    marginLeft: "35%",
    marginTop: "3%",
    color: "black",
    border: "1px solid black",
    backgroundColor: "#ffd966", // changed from bgcolor to backgroundColor
    borderRadius: "0px",
    boxShadow: "inset 0 -1px 0 0 #958383",
    "&:hover": {
      backgroundColor: "#ffd966",
    },
  },
  customMaxWidth: { // Use camelCase for consistency in JS objects
       // Default to 100%, then change at larger screen sizes
      "@media (min-width: 768px)": { // Correctly formatted media query
          maxWidth: "85%",
      }
  }
};
