import React from "react";
import { useSelector } from "react-redux";
import { STYLE_PLAN_TITLE } from "../../constants";
import { convertToHHMMSS, renderDescriptionWithLink } from "../../utils";
import "./styles.css"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
const ScheduleDescription=({details})=>{
      const {learningItem ,taskView,scheduleId } = details
      const navigate = useNavigate()

      const goBack=()=>{
         sessionStorage.setItem("scheduleId",scheduleId)
         sessionStorage.setItem("taskView",taskView)
         navigate("/dashboard")
      }
    return(
    <div className="col-md-8 container mt-4 px-3">
        <div className="study-plan-details p-4 mx-3" style={{minHeight:"41rem"}}>
            <ScheduleInfo />
            <div className="d-flex justify-content-center align-items-center">
              <div className="back-btn-style" onClick={() => goBack()} >
              <ArrowBackIosNewIcon /> <span className="go-back-style">Go Back</span>
              </div>
            </div>
            <ScheduleDetailsRendrer details={learningItem} />
        </div>
    </div>
    )

}


const ScheduleInfo = () => {
 const {scheduleDetails}  = useSelector(state  => state.schedule)
  return (
    <>
      <div className="alert alert-style" role="alert">
        <div className="row">
          <div className="col-9" style={{ paddingTop: "8px",textAlign: "left" }}>
            {STYLE_PLAN_TITLE}
          </div>
          <div className="col-3">
            <div style={{ fontSize: "10px", textAlign: "end" }}>
              {" "}
              {/* Adjust the font size as needed */}
              <strong style={{ fontWeight: 500 }}>Exam Date :</strong>{" "}
              {scheduleDetails?.schedulePlanDetails?.examDate}
              <br />
              <strong style={{ fontWeight: 500, right: "5px" }}>
                Exam Duration :
              </strong>{" "}
              {scheduleDetails?.schedulePlanDetails?.examDuration}
              <br />
              <strong style={{ fontWeight: 500 }}>Study Hours : </strong>
              {convertToHHMMSS(
                scheduleDetails?.schedulePlanDetails?.studyHours ?? 0
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



const ScheduleDetailsRendrer=({details})=>{

    const haveTitle=()=>{
        const contentDetails = details?.details
        const rootTitle = details?.title
        if(rootTitle){
            return rootTitle
        }
        if(contentDetails?.title){
            return contentDetails?.title
        }
        if(contentDetails?.Activity){
            return contentDetails?.Activity
        }
        return false
    }
    
      console.log("detailsdetailsdetailsdetailsdetailsdetailsdetails",details)
    return(<>
    {haveTitle() && <p className="activity-style" >{haveTitle()}</p>}
    {details?.details?.description &&
    <ul>
        <li>{details?.details?.description && <p className="activity-desc-style"style={{fontSize:"17px", fontWeight:"800",textAlign: "left"}} dangerouslySetInnerHTML={{ __html: renderDescriptionWithLink(details?.details?.description) }} />}</li>
    </ul>
    }
    {details?.details?.activities && 
        details?.details?.activities?.map((item,index)=> (
            <>
            <p  style={{fontSize:"17px", fontWeight:"800",textAlign: "left"}}>{item?.name}</p>
            {item?.description && 
            <ul>
            <li><p className="activity-desc-style" style={{fontSize:"17px", fontWeight:"800",textAlign: "left"}}dangerouslySetInnerHTML={{ __html: renderDescriptionWithLink(item?.description) }} /></li>
            </ul>}
            </>
        ))}
    {details?.["At_Bedtime_Everynight"]?.tasks?.length >0 && 
     details?.["At_Bedtime_Everynight"]?.tasks?.map((item,index)=> (
        <>
        <p style={{fontSize:"17px", fontWeight:"800",textAlign: "left"}} >{item?.name}</p>
        {item?.description && 
        <ul>
        <li><p className="activity-desc-style"style={{fontSize:"17px", fontWeight:"800",textAlign: "left"}} dangerouslySetInnerHTML={{ __html: renderDescriptionWithLink(item?.description) }} /></li>
        </ul>}
        </>
    ))}
    
    </>)
}




export default ScheduleDescription;