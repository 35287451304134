import "whatwg-fetch";
import React, { useEffect, useState } from "react";
import './styles.css'
const axios = require("axios");
import Scheduler from "devextreme-react/scheduler";
import TaskDetailsModal from "../StudyPlanDetails/TaskDetailModal";
import CustomStore from "devextreme/data/custom_store";
const moment = require('moment');
import { topicByDayOrganizer, topicByDayRendrer } from "../../utils/topicByDayUtil";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CalendarViewImg from "../../assets/images/CalendarView.png"
import * as ScheduleApi from '../../apis/schedule.api'

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { setCookie } from "../../utils";
import Loader from "../Loader";

const getData = async (_, requestOptions) => {
  const GOOGLE_CALENDAR_URL =
    "https://www.googleapis.com/calendar/v3/calendars/";
  const CALENDAR_ID = "f7jnetm22dsjc3npc2lu3buvu4@group.calendar.google.com";
  const PUBLIC_KEY = "AIzaSyBnNAISIUKe6xdhq1_rjor2rxoI3UlMY7k";
  const dataUrl = [
    GOOGLE_CALENDAR_URL,
    CALENDAR_ID,
    "/events?key=",
    PUBLIC_KEY,
  ].join("");
  const response = await fetch(dataUrl, requestOptions);
  const data = await response.json();
  return data.items;
};
const dataSource = new CustomStore({
  load: (options) => getData(options, { showDeleted: false }),
});
const currentDate = new Date();
const views = ["day", "workWeek", "month"];


const CalendarView = ( {tableData,setTableData,taskViewByDayData,setTaskViewByDayData,setIsPurchased,isPurchased,setShowPaymentModal}) => {
  console.log("taskViewByDayData",tableData)
  const [openTaskDetails, setOpenTaskDetails] = useState(false)
  const { userId, userToken } = useSelector((state) => state.user);
  const {scheduleId,scheduleDetails} = useSelector((state) => state.schedule)
  const [taskList, setTaskList]= useState({data :{}, itemNumber:null})
  const { rebaseStarted,rebaseHours } = useSelector(state => state.schedule)
  const[googleCalenderSSO,setGoogleCalenderSSO]=useState(false)
  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchData = async () => {
      setTableData((prevState)=>({...prevState, loading:true}))
      fetchSchedulePlanAndSetGoogleCalendarSSO()
     console.log("tableData.data",scheduleDetails)
      let transformedData =topicByDayOrganizer(scheduleDetails,false);
      if (tableData?.data) {
        console.log("rbaseander")
        if(rebaseHours && rebaseStarted){
          console.log("rbaseanderre")
          const finalRebaseHours = rebaseStarted ? rebaseHours : false
          transformedData = topicByDayRendrer(scheduleDetails,finalRebaseHours)
          console.log("transformedDataandrebase",transformedData)
        }
  
        if (transformedData?.length > 0) {
          console.log("transformedDataander",transformedData)
          setTaskViewByDayData((prevData) => ({ ...prevData, data: transformedData }));
        }
      }
      setTableData((prevState)=>({...prevState, loading:false}))

    };
  
    // Call the async function
    fetchData();
  }, [scheduleDetails]); // Added dependencies
  async function fetchSchedulePlanAndSetGoogleCalendarSSO() {
    const data = {
      taskView: 'calendarView', // Corrected spelling of "calendar"
      userId: userId,
      scheduleId: scheduleId,
    };
    try {
      const response = await ScheduleApi.retrieveSchedulePlan(data);
       console.log("response121",response)
      if (response.googleCalendarSSO) { // Corrected spelling of "Calendar"
        console.log("response.googleCalendarSSO", response.googleCalendarSSO);
        setGoogleCalenderSSO(response.googleCalendarSSO); // Corrected spelling of "Calendar"
      }
    } catch (error) {
      console.error('Failed to fetch schedule plan:', error);
    }
  }
  

const element=[]
taskViewByDayData?.data?.map((item, dayIndex) => {
  if(item?.data[0]?.date){
    element.push({
      startDate: moment(item?.data[0]?.date).format('YYYY-MM-DD'),
      title: item?.data[0]?.title,
      end: moment(item?.data[0]?.date).format('YYYY-MM-DD') ,
      item:item,
      dayIndex:dayIndex
    });
  }
  // else{
  //   toast.error("You need to start tracking first");
  //   return;
  // }
})

const openTaskListDetails = (item, itemNumber) => {
  
    setTaskList({ data: item, itemNumber: itemNumber  });
    setOpenTaskDetails(true);
  
};
const handleEventClick = (text,itemNumber) => {
 console.log("clicked",text,itemNumber)
};

const adjustedEvents = element.map(event => ({
  ...event,
  startDate: `${event.startDate}T09:00:00`,
  end:`${event.startDate}T18:00:00`
   // Append time and timezone information
}));

  console.log("adjustedEvents",adjustedEvents)
  const openAuthPopup = async() => {
    const data = {
      taskView: 'calendarView', // Corrected spelling of "calendar"
      userId: userId,
      scheduleId: scheduleId,
    };
    
      const response = await ScheduleApi.retrieveSchedulePlan(data);
       console.log("response121",response)
    if( !response.googleCalenderSSO){
      if(response.payment_status ){
        console.log("googleCalenderSSO",googleCalenderSSO)
        console.log("scheduleId", scheduleId);
        setCookie("scheduleId", scheduleId); // Assuming setCookie is defined elsewhere
    
        // Open a new window for the Google OAuth flow
        const googleAuthUrl = `https://backend.cheetahstudyplan.com/auth/google?userId=${encodeURIComponent(userId)}`; // Your backend endpoint
        const width = 600;
        const height = 600;
        const left = (window.outerWidth - width) / 2;
        const top = (window.outerHeight - height) / 2.5;
    
        const authWindow = window.open(
            googleAuthUrl,
            'AuthWindow',
            `toolbar=no, location=no, directories=no, status=no, menubar=no,
            scrollbars=yes, resizable=yes, copyhistory=no, width=${width},
            height=${height}, top=${top}, left=${left}`
        );
    
        // Polling for a response from the pop-up
        const pollTimer = window.setInterval(() => {
            try {
                if (authWindow.closed) {
                    window.clearInterval(pollTimer);
                    console.log('Authentication was not completed');
                    // Invoke callback or event signaling the user closed the window without completing auth
                } else if (authWindow.location.href.includes('callback')) {
                    // Assuming your redirect URI includes 'callback'
                    window.clearInterval(pollTimer);
                    authWindow.close(); // Close the auth window if authentication was successful
                    console.log('User authenticated');
                    // Handle successful authentication here, such as fetching user data
                    // You may want to invoke a callback or dispatch an event here
                }
              
            } catch (e) {
                // Errors are normal if the pop-up has navigated to another origin
                // It's important not to disclose details of the error for security reasons
            }
        }, 500);
      }
      else{
        toast.error('Complete your purchase to avail this feature.')
      }
     
    }
    else{
      toast.error("already syncup")
    }
};

  // const opensncup = async() => {
  // axios.post('http://localhost:3100/sync-events', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: {
  //       userId: userId,
  //       events: adjustedEvents,
  //     },
  //     credentials: 'include' // Use only if you're handling user sessions and need to include cookies
  //   })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Network response was not okay');
  //     }
  //     return response.json();
  //   })
  //   .then(data => console.log('Success:', data))
  //   .catch(error => console.error('Error:', error));
  // }
    
   
const openPurchaseModal=()=>{
  setShowPaymentModal(true);
}
return (
  <React.Fragment>
  {  console.log('taskList?.data',taskList?.data)}
    {tableData?.loading && <Loader show={tableData?.loading} />}

    {taskList?.data && taskList?.itemNumber != null && (
      <TaskDetailsModal
        openTaskDetails={openTaskDetails}
        setOpenTaskDetails={setOpenTaskDetails}
        taskList={taskList}
        setTableData={setTableData}
        tableData={taskViewByDayData}
        taskView="calendarView"
        setShowPaymentModal={setShowPaymentModal}
      />
    )}
<button type="button"
        className="btn btn-warning homepage-btn-style study-plan-action-style mt-3 col-md-2 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#1155cc",
          color: "white",
          border: "1px solid black",
          bottom: "10%",
          width: "30%",
          marginBottom:"20px"
        
        }}
      
      onClick={openAuthPopup}>Sync with Google Calendar</button>

    {isPurchased ? (
      <>
       {console.log("schedulerender")}
         <Scheduler
        dataSource={adjustedEvents.map((event) => ({
          startDate: event?.startDate ?? "",
          endDate: event?.end ?? "",
          text: event?.title,
          item: event.item,
          dayIndex: event.dayIndex,
        }))}
        views={views}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        height={700}
        startDayHour={7}
        editing={true}
        showAllDayPanel={true}
        startDateExpr="startDate"
        endDateExpr="endDate"
        textExpr="text"
        //timeZone="America/Los_Angeles"
        onAppointmentClick={(e) =>
          openTaskListDetails(
            e.appointmentData.item,
            e.appointmentData.dayIndex
          )
        }
      /></>
   
    ) : (
      <div style={{ position: "relative", display: "inline-block" }}>
      <img
        src={CalendarViewImg}
        alt="calendar-view"
        onClick={openPurchaseModal}
        style={{ width: "100%" }}
      />
      <button
        type="button"
        className="btn btn-warning homepage-btn-style study-plan-action-style mt-3 col-md-2 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#1155cc",
          color: "white",
          border: "1px solid black",
          bottom: "50%",
          width: "20rem",
          left: "32%",
          position: "absolute",
          zIndex: 2,
        }}
        onClick={() => setShowPaymentModal(true)}
      >
        Purchase To Continue
      </button>
    </div>
    )}
  </React.Fragment>
);

}



export default CalendarView;