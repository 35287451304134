import apiClient from "../apiClient";


export const getMarketingContent = async() => {
    try {
      const response = await apiClient.get(`/marketing-content/list`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
