import apiClient from "../apiClient";


export const googleLogIn = async(data) =>{
    try {
      const response = await apiClient.post(`/auth/google-sso`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const ForgotPassword = async(data) => {
    try {
      const response = await apiClient.post(`/auth/forgot-password`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const resetPassword = async(data) => {
    try {
      const response = await apiClient.post(`/auth/reset-password`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };