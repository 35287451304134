// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-list-table-style {
    font-size: 21px;
    font-weight: 500;
}

input[type="checkbox"]:disabled {
    cursor: "not-allowed";
    opacity: 0.5; 
  }
  p.learn-more-style {
    padding: 0px;
    margin: 0px;
    color: blue;
}
p.learn-more-style:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/TaskViewByDay/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,YAAY;IACZ,WAAW;IACX,WAAW;AACf;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".task-list-table-style {\n    font-size: 21px;\n    font-weight: 500;\n}\n\ninput[type=\"checkbox\"]:disabled {\n    cursor: \"not-allowed\";\n    opacity: 0.5; \n  }\n  p.learn-more-style {\n    padding: 0px;\n    margin: 0px;\n    color: blue;\n}\np.learn-more-style:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
