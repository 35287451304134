import React, { useEffect, useState } from "react";
import "./styles.css"
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { scheduleDetailsOrganzier, updatePaymentStatus } from "../../utils";
import { updateScheduleDetails } from "../../store/schedule/scheduleSlice";
import * as ScheduleApi from "../../apis/schedule.api"
import { updateScheduleListDetails } from "../../store/scheduleList/scheduleListSlice";

const ScheduleLists =({setTableData,setIsPurchased,screen})=>{
    const { scheduleDetails, scheduleId,taskView } = useSelector(state => state.schedule)
    const { userId } = useSelector(state => state.user)

    const {scheduleListDetails} = useSelector(state => state.scheduleList)
    const dispatch = useDispatch()
  
    // console.log("scheduleDetailsscheduleDetails",scheduleDetails)
    const navigate = useNavigate()

    // useEffect(()=>{
    //    if(scheduleListDetails && scheduleListDetails.length >0){
    //       const scheduleId = scheduleListDetails[0]?.scheduleId
    //       console.log("scheduleId",scheduleId)
    //       setActiveSchedule(scheduleId)
    //    }
    // },[])

    const shiftSchedule=async(scheduleId)=>{
      
      try {
        if(screen =="ScheduleDetails"){
             sessionStorage.setItem("scheduleId",scheduleId)
             navigate("/dashboard")
        }else{
          setTableData((prevState)=>({...prevState, loading:true}))
          dispatch(updateScheduleDetails({}))
           const organizedData = scheduleDetailsOrganzier(scheduleListDetails,scheduleId)
          //  console.log("organizedDataorganizedData",organizedData)
           const updatedRes ={
             json : JSON.stringify(organizedData),
             status: true,
             filePath :organizedData["excelUrl"],
             taskView : "taskViewByDay",
             payment_status : organizedData["payment_status"],
             trackingStarted : organizedData["trackingStarted"],
             scheduleId : organizedData["scheduleId"],
             rebaseStarted : organizedData["rebaseStarted"],
             rebaseHours : organizedData["rebaseHours"],
             googleCalenderSSO:organizedData['googleCalenderSSO']
           }
           if(organizedData){
            const scheduleListDetails  = await ScheduleApi.scheduleListDetails({taskView : taskView , userId:userId})
            if(scheduleListDetails?.status){
              console.log("fdgh")
              const data = JSON.parse(scheduleListDetails?.json)
              dispatch(updateScheduleListDetails(data))
            }
         
            // console.log("dispatch 33333333333333333333", updatedRes)
            dispatch(updateScheduleDetails(updatedRes))
            setTableData({data : organizedData, taskView : "taskViewByDay", loading: true,filePath: organizedData?.excelUrl ?? ""})
            setIsPurchased(organizedData?.payment_status)   

           }

        }
        
      } catch (error) {
        setTableData((prevState)=>({...prevState, loading:false}))
         console.error("erroe while shifting schedule",error)
      }
    }


    return (
      <div className="col-md-2  col-sm-2 col-xs-2 container mt-4 px-3 schedule-list-styles">
        <div className="justify-content-around align-items-center" >
          <Typography variant="h6" gutterBottom id="heading-style">
            Schedule's List
            <IconButton onClick={() =>navigate("/")}>
              <ControlPointIcon />
            </IconButton>
          </Typography>
          
        </div>

       {scheduleListDetails && scheduleListDetails.map((item,index) =>{
         const reversedIndex = scheduleListDetails.length - index;
        return(<>
          <button
              type="button"
              className="btn btn-warning homepage-btn-style study-plan-action-style mb-3"
              style={(scheduleId ===item.scheduleId) ? {width:"95%"}:{width:"95%", border :"none", backgroundColor :"white"}}
              onClick={() => shiftSchedule(item?.scheduleId)}
              
            >
              {`Schedule ${reversedIndex}`}
            </button>
          </>)
        })}
    </div>
  );
}

export default ScheduleLists