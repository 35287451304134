
import React, { useEffect } from "react";
import { FullStory } from '@fullstory/browser';
import { useSelector } from "react-redux";
import Quotes from "../components/Quotes/Quotes";
import ScheduleStudyPlan from "../components/ScheduleStudyPlan/ScheduleStudyPan";
import "../styles/index.css";

const Homepage = () => {
  const { userId, userInfo } = useSelector(state => state.user);
  useEffect(()=>{
    if(userInfo?.email){
      FullStory(userInfo?.email, {
        uid: userId,
        properties: {
          displayName: userInfo?.firstName + " " + userInfo?.lastName,
        }
      });
    }
  },[userInfo])
  return (<>
      <Quotes/>
      <ScheduleStudyPlan/>
  </>
  );
};

export default Homepage;
