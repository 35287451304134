import React, { useEffect, useRef } from 'react';
import './ChatWindow.css';
import avatar from "../../assets/images/star.png";
import UserAvatar from "../../assets/images/useravatar.png";

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,

} from '@mui/material';
import AdvertisementSection from '../Advertisement/AdvertisementSection';
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
const formatMessage = (msg) => {
  if (typeof msg.text === 'string') {
    // Check if the message is a plain string or a structured object
    try {
      const json = JSON.parse(msg.text);
      if (json.ai_reply) {
        return json.ai_reply;
      }
    } catch (e) {
      return msg.text;
    }
  } else if (typeof msg.text === 'object' && msg.text.ai_reply) {
    return msg.text.ai_reply;
  }
  return msg.text;
};

const renderMessage = (msg) => {
  const formattedMessage = formatMessage(msg);

  if (typeof formattedMessage === 'string') {
    const listItems = formattedMessage.split(/(\d+\.\s[^\d]+)/g).filter(Boolean); 
    return listItems.map((item, index) => {
      const parts = item.split(/(:\s+)/); // Split at ": " to separate the heading
      return (
        <div key={index} style={{ marginBottom: '0.5rem' }}>
          {parts.length > 1 ? (
            <>
              <strong>{parts[0]}</strong>{parts[1]}{parts[2]}
            </>
          ) : (
            item
          )}
        </div>
      );
    });
  }
};



const ChatWindow = ({ messages, loading, initialMessage, onSend }) => {

  const { userToken, userId, userInfo } = useSelector(state => state.user);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    console.log("useEffect triggered");
    if (chatWindowRef.current) {
      console.log("chatWindowRef.current is set");
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    } else {
      console.log("chatWindowRef.current is null");
    }
  }, [messages])

  console.log("initialMeessage", initialMessage.starting_questions)
  return (
    <div className="chat-window p-3" ref={chatWindowRef} style={{ height: '600px', overflowY: 'auto' }}>
      {messages.length === 0 ? (
        <div className="container d-flex flex-column text-center mt-5 gap-5">
          <div>
            <h4><b>{initialMessage?.name ?? ''}</b></h4>
            <p>{initialMessage?.description ?? ''}</p>

          </div>
          <div className="starting-questions d-flex   gap-2">
            {initialMessage && initialMessage?.starting_questions?.length > 0 && initialMessage?.starting_questions?.map((question, index) => (
              <div
                key={index}
                className="question-button p-3 border rounded bg-light w-50"
                onClick={() => onSend(question)}
                style={{ height: '100%' ,minHeight:'80px' }}
              >
                {question}
              </div>
            ))}
          </div>

        </div>
      ) : (
        messages.map((msg, index) => (
          <div
            key={index}
            className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}
            style={{ alignItems: 'center', gap: '15px' }}
          >
            {msg.sender !== 'user' && (
              <img src={avatar} alt="Bot Avatar" className="avatar me-2" />
            )}
                <div className={`message ${msg.sender === 'user' ? 'user-message' : 'bot-message'} p-2 rounded`}>
            {renderMessage(msg)}
          </div>
            {msg.sender === 'user' && userInfo && (
              <img src={UserAvatar} alt="User Avatar" className="avatar me-2" />
            )}
          </div>
        ))
      )}
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: "100%", marginTop: "3%" }}>
          <Avatar
            src={avatar}
            alt="AI Avatar"
            sx={{ width: 40, height: 40, marginRight: 1 }}
          />
          <Box sx={{ width: "80%", height: "70%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      ) : null}
    </div>
  );
}

export default ChatWindow;

