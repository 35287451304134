import React, { useState } from "react";
import AdvertisementSection from "../Advertisement/AdvertisementSection";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  ListItemIcon,
} from "@mui/material";
import { classes } from "./style";
import Quotes from "../Quotes/Quotes";
import DatePicker from "react-datepicker";

// import MyTooltip from "../../utils/MyTooltip/MyTooltip";
import { useLocation, useNavigate } from "react-router-dom";
import * as ScheduleApi from "../../apis/schedule.api";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StudyTimeArea from "./StudyTimeArea";

function Reschedule() {
  const [isPrepared, setIsPrepared] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [allocateChecked, setAllocateChecked] = useState(false);
  const [rescheduleChecked, setRescheduleChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  
  const handleDateChange = (date) => {
    const newDate = new Date(date);

    // Use Intl.DateTimeFormat to format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(newDate);

    console.log(formattedDate);
    setSelectedDate(formattedDate);
  };

  const handleAllocateChange = (event) => {
    setAllocateChecked(event.target.checked);
    if (event.target.checked) {
      setRescheduleChecked(false); // Uncheck reschedule checkbox when allocating
    }
  };

  const handleRescheduleChange = (event) => {
    setRescheduleChecked(event.target.checked);
    if (event.target.checked) {
      setAllocateChecked(false); // Uncheck allocate checkbox when rescheduling
    }
  };

  
  React.useEffect(() => {
    console.log("neededStudyHours", location.state.neededStudyHours);
    if (!location.state.status) {
      setIsPrepared(false);
    } else {
      setIsPrepared(true);
    }
  }, [location.state]);
  const generateSchedulePlan = async () => {
    if (isPrepared) {
      setLoader(true);

      if (location.state.status) {
        let studyPlanFormData  = sessionStorage.getItem("studyPlanFormData")

        let userId = localStorage.getItem("userId")
        studyPlanFormData = JSON.parse(studyPlanFormData)
        try {
          
          const data={
            userId: userId,
            studyPlanFormData:studyPlanFormData
          }
          console.log("rishabtesttt")
          await ScheduleApi.update_schedule_ac(data)
        } catch (error) {
          console.log("activecampaign update error",error)
        }
       navigate("/dashboard", { state: location?.state });
      }
      setLoader(false);
    } else {
      if(allocateChecked){
        setLoader(true);

        const hours  = location?.state?.neededStudyHours;
        let studyPlanFormData  = sessionStorage.getItem("studyPlanFormData")
        let proeficiencyFormData = sessionStorage.getItem("proeficiencyFormData")
        proeficiencyFormData = JSON.parse(proeficiencyFormData)

        let userId = localStorage.getItem("userId")

        studyPlanFormData = JSON.parse(studyPlanFormData)
        studyPlanFormData = {...studyPlanFormData , ...{studyHours : hours}}
        //  console.log("studyPlanFormData",studyPlanFormData)
        const resultantData ={
          schedulePlanDetails :studyPlanFormData,
          topicDetails : proeficiencyFormData,
          userId: userId,
    

        }
        const result = await ScheduleApi.createSchedulePlan(resultantData)
        try {
          const data={
            userId: userId,
            studyPlanFormData:studyPlanFormData
          }
          await ScheduleApi.update_schedule_ac(data)
        } catch (error) {
          console.log("activecampaign update error",error)
        }
        
        setLoader(false)
        navigate('/dashboard',{state:result});


   }else if(rescheduleChecked){
    if(!selectedDate){
      toast.error('Please Select date to Proceed')
      return
    }
    
    setLoader(true);
    
  
    let studyPlanFormData  = sessionStorage.getItem("studyPlanFormData")
    let proeficiencyFormData = sessionStorage.getItem("proeficiencyFormData")
    proeficiencyFormData = JSON.parse(proeficiencyFormData)

    let userId = localStorage.getItem("userId")

    studyPlanFormData = JSON.parse(studyPlanFormData)
    studyPlanFormData = {...studyPlanFormData , ...{examDate : selectedDate}}
    //  console.log("studyPlanFormData",studyPlanFormData)
    const resultantData ={
      schedulePlanDetails :studyPlanFormData,
      topicDetails : proeficiencyFormData,
      userId: userId,

    }
   sessionStorage.setItem("studyPlanFormData",JSON.stringify(studyPlanFormData))
   // sessionStorage.setItem(proeficiencyFormData)
    const result = await ScheduleApi.createSchedulePlan(resultantData)
    setLoader(false)
    if(result.status){
      navigate('/dashboard',{state:result});
    }
    else{
      // toast.error(`Please choose a date from ${selectedDate} onwards`)
      toast.error(`It seems you require more study hours before your exam. Choose given  time to study or rescheduling your exam date.`)
      navigate('/reschedule',{state:result});
    }
    
 
   }
    
   else{
      toast.error("Please select option to continue !!")
      return;
   }
    }
  };


  function convertToHHMMSS(time) {
    var hours, minutes, seconds;

    if (Number.isInteger(time)) {
      hours = Math.floor(time / 3600);
      minutes = Math.floor((time % 3600) / 60);
      seconds = Math.floor(time % 60);
    } else {
      hours = Math.floor(time);
      minutes = Math.floor((time - hours) * 60);
      seconds = Math.floor(((time - hours) * 60 - minutes) * 60);
    }

    // Format the output
    var HH = ("0" + hours).slice(-2);
    var MM = ("0" + minutes).slice(-2);
    var SS = ("0" + seconds).slice(-2);

    return HH + ":" + MM + ":" + SS;
  }
  const isGreaterThan12 = location.state.neededStudyHours > 12;


  const getTodayDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 2);

    const year = tomorrow.getFullYear();
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
    const day = tomorrow.getDate().toString().padStart(2, "0");
    "MM/dd/yyyy"

    return `${month}/${day}/${year}`;
};
  return (
    <>

      <Loader show={loader} />
      <div className="row">
        <Box sx={classes.customMaxWidth}>
          <Quotes />
          <Card variant="outlined" sx={{ bgcolor: "#f5f5f5" }}>
            <CardContent>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ textAlign: "center" }}
                className="subTitle-heading"
              >
                <b className="c-site-heading">Based on Your Proficiency in Each Topic On Your Exam</b>
              </Typography>
 
              {isPrepared ? (
                <div className="row">
                  <div className="col-12">
                    <StudyTimeArea/>
                  </div>
                  <div className="col-12 mt-4">
                    <Typography gutterBottom sx={classes.content}>
                      Good job, you’ve allocated sufficient time to prepare for your
                      exam.
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <StudyTimeArea/>
                  <div className="col-12 mt-4">
                    <Typography gutterBottom sx={classes.rescheduleContent}>
                      {!isGreaterThan12 ? (
                        // Render this when neededStudyHours is greater than 12
                        <>
                          Even by accelerating your exam prep, you need to
                          allocate more time to study for your exam. Based on when
                          your exam is we recommend you either:
                        </>
                      ) : (
                        <>
                          Even by accelerating your exam prep, you need to
                          allocate more time to study for your exam. Based on when
                          your exam is we recommend you:
                        </>
                      )}
                      
                      {/* Always render this */}
                      <div className="row">
                          <div className="col-12 m-auto text-center mt-4">
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={rescheduleChecked}
                                      onChange={handleRescheduleChange}
                                  />
                              }
                              label={
                                <>
                                    <Typography><b>Reschedule Your Exam Date <CalendarMonthIcon /></b></Typography>
                                </>
                            }
                          />
                          </div>
                          <div className="col-12 m-auto text-center">
                          {rescheduleChecked && (
                              <DatePicker
                                  name="exam_date"
                                  placeholderText="MM/DD/YYYY"
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control my-2 generate-study-inp-field"
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  required
                                  minDate={getTodayDate()}
                                  autoComplete="off"
                              />
                          )}
                          </div>
                      </div>
                      {!isGreaterThan12 &&
                        // Render this when neededStudyHours is greater than 12
                        <>
                          <b style={classes.seprator}>OR</b>
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={allocateChecked}
                                onChange={handleAllocateChange}
                              />
                            }
                            label={
                              <Typography>
                                <b>Allocate{" "}
                                <strong>
                                  {location?.state?.hrsToStudyHHMMSS}
                                </strong>{" "}
                                Hours Per Day to Study</b>
                              </Typography>
                            }
                          />
                        </>
                      }
                    </Typography>
                  </div>
                </div>
              )}
              <div className="col-12 text-center">
                <Button
                  variant="contained"
                  sx={classes.continueBtn}
                  onClick={generateSchedulePlan}
                  className="m-auto mt-4"
                >
                  Continue
                </Button>
              </div>
            </CardContent>
          </Card>
        </Box>
        <AdvertisementSection />
      </div>
    </>
  );
}

export default Reschedule;