import React, { useEffect, useState } from "react";
import AdvertisementSection from "../components/Advertisement/AdvertisementSection";
import Quotes from "../components/Quotes/Quotes";
import ScheduleLists from "../components/ScheduleLists/ScheduleLists";
import StudyPlanDetails from "../components/StudyPlanDetails/StudyPlanDetails";
import * as ScheduleApi from "../apis/schedule.api";
import { useDispatch, useSelector } from "react-redux";
import { scheduleDetailsOrganzier } from "../utils";
import { updateScheduleDetails } from "../store/schedule/scheduleSlice";
import { updateScheduleListDetails } from "../store/scheduleList/scheduleListSlice";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [tableData, setTableData] = useState({
    data: [],
    taskView: "taskViewByDay",
    loading: true,
    filePath: ""
  });
  const [isPurchased, setIsPurchased] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const { scheduleListDetails } = useSelector(state => state.scheduleList);
  const { scheduleDetails, trackingStarted, rebaseStarted, rebaseHours } = useSelector(state => state.schedule);
  const { sid } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    const proeficiencyFormData = sessionStorage.getItem("proeficiencyFormData");
    const studyPlanFormData = sessionStorage.getItem("studyPlanFormData");
    if (proeficiencyFormData) {
      sessionStorage.removeItem("proeficiencyFormData");
    }
    if (studyPlanFormData) {
      sessionStorage.removeItem("studyPlanFormData");
    }
  }, []);

  useEffect(() => {
    
    retrieveUserDetails();
  }, []);

  const retrieveUserDetails = async () => {
    try {
      setTableData((prevState) => ({ ...prevState, loading: true }));
      const userId = localStorage.getItem("userId");
      const data = {
        taskView: tableData.taskView,
        userId: userId
      };
      const response = await ScheduleApi.scheduleListDetails(data);
      if (response?.status) {
        const data = JSON.parse(response?.json);
        if (data?.length > 0) {
          const scheduleId = sessionStorage.getItem("scheduleId") ?? data[0]?.scheduleId;
          const taskView = sessionStorage.getItem("taskView") ?? tableData?.taskView;
          const organizedData = scheduleDetailsOrganzier(data, scheduleId);
          const updatedRes = {
            json: JSON.stringify(organizedData),
            status: true,
            filePath: organizedData["excelUrl"],
            taskView: taskView,
            payment_status: organizedData["payment_status"],
            trackingStarted: organizedData["trackingStarted"],
            scheduleId: organizedData["scheduleId"],
            rebaseStarted: organizedData["rebaseStarted"],
            rebaseHours: organizedData["rebaseHours"],
            googleCalenderSSO: organizedData['googleCalenderSSO'],
          };
          dispatch(updateScheduleDetails(updatedRes));
          dispatch(updateScheduleListDetails(data));

          setTableData({
            data: organizedData,
            taskView: taskView,
            loading: true,
            filePath: organizedData?.excelUrl ?? ""
          });
          removeSessionStorage();
          if (organizedData.payment_status) {
            const checkIspurcahsed = organizedData.payment_status;
            setIsPurchased(checkIspurcahsed);
            if (checkIspurcahsed) {
              setShowPaymentModal(false);
            }
          }
        }
      }
      
      else{
        console.log("checkloader")
        setTableData((prevState) => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      console.error("error while retrieving user details", error);
      if (location?.state?.json) {
        const formattedData = JSON.parse(location?.state?.json);
        const taskView = location?.state?.taskView;
        setTableData({
          data: formattedData,
          taskView: taskView,
          loading: false,
          filePath: location?.state?.filePath ?? ""
        });
      }
    }
  };

  const removeSessionStorage = () => {
    try {
      if (sessionStorage.getItem("scheduleId")) {
        sessionStorage.removeItem("scheduleId");
      }
      if (sessionStorage.getItem("taskView")) {
        sessionStorage.removeItem("taskView");
      }
    } catch (error) {
      console.log("remove session storage", error);
    }
  };
console.log("checkdashboard")
  return (
    <>
      <Quotes />
      <div className="row" style={{ minHeight: "57rem" }}>


        {scheduleListDetails.length > 0 && (
          <ScheduleLists
            setTableData={setTableData}
            setIsPurchased={setIsPurchased}
            screen={"Dashboard"}
          />
        )}
        {scheduleListDetails.length > 0 && (
          <StudyPlanDetails
            tableData={tableData}
            setTableData={setTableData}
            isPurchased={isPurchased}
            setIsPurchased={setIsPurchased}
            showPaymentModal={showPaymentModal}
            setShowPaymentModal={setShowPaymentModal}
          />
        )}
              {!tableData?.loading && scheduleListDetails.length  === 0 && (
            <div className="create-schedule-message" style={{textAlign:'center',marginTop:"10%"}}>
              <h3 >No active schedule found. Please check your schedule or create a new one</h3>
              <button onClick={() => navigate('/')} type="button" class="btn btn-primary generate-study-btn" style={{backgroundColor :"#e5a32c", color:"white", border:"none"}}>Create a schedule</button>
            </div>
          )}
      {Object.keys(tableData?.data).length > 0 && (
  <AdvertisementSection />
)}
        
      </div>
      <Loader show={tableData.loading} />
    </>
  );
};

export default Dashboard;
