import React, { useState } from 'react';
import * as AuthApi from "../../apis/authentication.api"
import Loader from "../Loader";
import { Link } from 'react-router-dom';
import "./styles.css"
import { toast, ToastContainer } from 'react-toastify';
function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = React.useState(false);
    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        setMessage('');
        setError('');

        try {
            const response=await AuthApi.ForgotPassword({email:email})
            setLoader(false)
            //const data = await response.text();
            if (response?.status) {
                setMessage('A reset link has been sent to your email address.');
                setEmail(''); // Optionally clear the email input on success
            } else {
                setError(response?.msg);
            }
        } catch (err) {
            setError('We could not find an account with that email address.');
            console.error('Reset link error:', err);
        }
    };



    return (
        <>
         <ToastContainer />
         {loader && <Loader show={loader} />}
   
        <div className="containerStyle">
            <h4>Forgot Password</h4>
            <form onSubmit={handleSubmit} className="formStyle">
                <label htmlFor="email" className="labelStyle">Email Address:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="inputStyle"
                />
                <button type="submit" className="buttonStyle">Send Reset Link</button>
            </form>
            
            <div style={{ padding: '10px' }}>
            Need an Account? <Link to="/">Register here</Link>
            </div>
            {message && <p className="messageStyle">{message}</p>}
            {error && <p className="errorStyle">{error}</p>}
        </div></>
       
    );
}

export default ForgotPassword;
