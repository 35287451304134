import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import * as PaymentApi from  "../../apis/payment.api"
import * as ScheduleApi from '../../apis/schedule.api'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { toast } from "react-toastify";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { updateScheduleDetails } from "../../store/schedule/scheduleSlice";
import { updatePaymentStatus } from "../../utils";
import { updateScheduleListDetails } from "../../store/scheduleList/scheduleListSlice";
import { getCurrentUser } from "../../store/user/userAction";
import PaymentCompleteModal from "./PaymentCompleteModal";



const PaymentCheckoutForm = ({ setShowCheckoutForm, showCheckoutForm ,setIsPurchased,setTableData,tableData,taskView,chargeAmount}) => {
  const stripe = useStripe();
  const { scheduleId, userId} = useSelector(state => state.schedule)
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(null);
  const [message, setMessage] = useState('');
  const [showInput, setShowInput] = useState(false);
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [paymentCompleted, setPaymentCompleted] = useState(false)
  const { userInfo } = useSelector(state => state.user)
  const [email, setEmail] = useState(userInfo.email || '');
  const [isLoading, setIsLoading] = useState(false); 
  const {scheduleListDetails} = useSelector(state => state.scheduleList)
  const dispatch = useDispatch()
  const closeModal = () => {
    setShowCheckoutForm(false);
  };

  const handleSubmit = async (event) => {
    console.log("checkingduration",tableData?.data?.schedulePlanDetails?.examDuration)
    event.preventDefault();
   
    setIsLoading(true); 
    if (!stripe || !elements) {
      console.log('Stripe has not loaded yet.');
      setIsLoading(false); 
      return;
    }
    
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.log('Card Element not found');
      setIsLoading(false); 
      return;
    }
    const userId = localStorage.getItem("userId")
    dispatch(getCurrentUser({userId}))
    try {
      // Construct data for the backend
      let paymentData = {
        email: userInfo?.email,
        name: cardholderName,
        zipcode: zipcode,
        scheduleId: scheduleId,
        examDuration: tableData?.data?.schedulePlanDetails?.examDuration
      };
  
      // Call backend to create PaymentIntent and get clientSecret
      const { success, clientSecret } = await PaymentApi.chargePayment(paymentData);
  
      if (!success || !clientSecret) {
        setIsLoading(false); 
        alert('Failed to initialize payment.');
        return;
      }
  
      // Attempt to confirm the payment on the client
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { name: paymentData.name, email: paymentData.email },
        },
      });
  
      if (result.error) {
        console.log('Payment failed:', result);
        setIsLoading(false); 
        
        toast.error('Payment failed: ' + result.error.message);
        
        return; // Stop execution if there's an error
      }
  
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        console.log("teripendiii")
        console.log('Payment succeeded!',result);
     
        setIsPurchased(true)
     
       
     
       
  
        // Update server with payment success
        const updateData = {
          userId: userInfo.userId,
          idsList:[] ,// Assuming `userInfo` has `userId`
          payment_status: true,
          scheduleId : scheduleId,
          taskView:taskView
        };
        const updateRes = await ScheduleApi.updateSchedulePlan(updateData);
  
        if (updateRes && updateRes.status) {
          toast.success("Purchase completed successfully! Now access comprehensive study schedule.");
          const updatedDate =  {...updateData , ...{payment_status : true, trackingStarted : updateRes["trackingStarted"]}}
          const updatedDataResponse = updatePaymentStatus(scheduleId,scheduleListDetails)
          dispatch(updateScheduleListDetails(updatedDataResponse))
          dispatch(updateScheduleDetails(updateRes))
          setTableData({data : updatedDate, taskView : updateRes.taskView, loading: false,filePath: updateRes?.filePath ?? ""})
          setIsLoading(false); 
          setShowCheckoutForm(false);
          await sendingEmailNotification(); // Ensure this function is defined to send notifications
        } else {
          // Handle failure to update the server
          setIsLoading(false)
          console.error('Failed to update server with payment status.');
          toast.success('Payment processed successfully, yet unable to update your schedule. Please reach out to admin for assistance.');
        }
      }
  
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment error: ' + error.message);
      setIsLoading(false); // Stop loading due to exception
    } 
  };
  
  const handleApplyPromo = async () => {
    try {
      const { success, clientSecret } = await PaymentApi.promoCode( { code: promoCode });
      setDiscount(response.data.discount);
      setMessage(`Promo code applied! You get ${response.data.discount}% off.`);
    } catch (error) {
      setMessage(error.response.data.message || 'Error applying promo code');
    }
  };
  async function sendingEmailNotification() {
    try {
      if(userInfo?.email){
        // URL to send the request to
        const url =
          "https://services.leadconnectorhq.com/hooks/C0FdQ0aF2iPmvHNvd4ZQ/webhook-trigger/1d3cb8cc-264d-4e41-902f-4e03b3a62722";
        
        const data  = {
          email : userInfo?.email
        }
    
        // Make POST request using Axios
        axios
          .post(url, data)
          .then((response) => {
            console.log("Response:", response.data);
            return true
          })
          .catch((error) => {
            console.error("Error:", error);
            return false
          });
        
      }
    } catch (error) {
      console.log("error while sending email notifications", error);
      return false
    }
  }
  

  return (
 
    <>
       {console.log("setPaymentCompleted",paymentCompleted)}
   <PaymentCompleteModal paymentCompleted={paymentCompleted}setPaymentCompleted={setPaymentCompleted}/>
    <Modal
      className="buy-session-video-modal"
      open={showCheckoutForm}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>

        <ClearIcon
          style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
          onClick={closeModal}
        />
         <p className="purchase-rec-head-style">
            <strong>Add Your Card Details</strong>
            
            </p>
        <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
    
        {isLoading && <Loader show={isLoading} />}
        <label style={{ marginBottom: "10px", display: "block" }}>
              Cardholder Name
              <input
                type="text"
                value={cardholderName}
                placeholder="Cardholder Name"
                onChange={(e) => setCardholderName(e.target.value)}
                style={inputStyle}
                required
              />
            </label>
        
          <label style={{ marginBottom: "10px", display: "block" }}>
            Card number
            <div id="stripe-input" style={{border : "1px solid #ccc", width:'100%',padding:'11px',borderRadius:'5px'}}>
            <CardNumberElement  />
            </div>
          </label>
          <label style={{ marginBottom: "10px", display: "block",borderRadius:"2px solid red" }}>
            Expiration date
            <div id="stripe-input" style={{border : "1px solid #ccc", height: "38px",padding:'11px',borderRadius:'5px'}}>
            <CardExpiryElement className="stripe-card-style font-nova bodr-r width-50px" />
            </div>
          </label>
          <label style={{ marginBottom: "10px", display: "block" }}>
            CVC
            <div id="stripe-input" style={{border : "1px solid #ccc", height: "38px",padding:'11px',borderRadius:'5px'}}>
            <CardCvcElement className="stripe-card-style font-nova bodr-r width-50px" />
            </div>
          </label>
          <label style={{ marginBottom: "10px", display: "block" }}>
          zipcode
            <input
              type="text"
              value={zipcode}
              placeholder="zipcode"
              onChange={(e) => setZipcode(e.target.value)}
              style={inputStyle}
              required
            />
          </label>
          {/* <div>
      {!showInput ? (
        <a href="#" onClick={() => setShowInput(true)}>Apply Promo Code</a>
      ) : (
        <div>
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
          <button onClick={handleApplyPromo} style={{
            
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '10px',
          }}>
            Apply Promo Code
          </button>
        </div>
      )}
      {message && <p>{message}</p>}
    </div> */}

          <button type="submit" disabled={!stripe} style={submitButtonStyle}>
            Pay ${(tableData?.data?.schedulePlanDetails?.examDuration*chargeAmount).toFixed(2)}
          </button>
        </form>
      </Box>
    </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  minWidth: "300px",
  maxWidth: "400px",
  width: "80%",
  borderRadius: "10px",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  marginBottom: "10px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const submitButtonStyle = {
  backgroundColor: "#4CAF50",
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "100%",
};

export default PaymentCheckoutForm;
