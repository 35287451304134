import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import MyTooltip from '../../utils/MyTooltip/MyTooltip';
import * as ScheduleApi from '../../apis/schedule.api'
import { useDispatch, useSelector } from 'react-redux';
import { convertToHours, ProgressCalculator } from '../../utils';
import { topicByDayOrganizerWithRebase } from '../../utils/topicByDayForRebase';
import Loader from '../Loader';
import { updateScheduleDetails } from '../../store/schedule/scheduleSlice';
import { topicByDayOrganizer, topicByDayRendrer } from '../../utils/topicByDayUtil';
import { useNavigate } from "react-router-dom";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RescheduleExamDate({rebaseRescheduleModal,setRebaseRescheduleModal,rebaseHours,setTaskViewByDayData,tableData}) {

  const handleClose = () => setRebaseRescheduleModal(false);
  const navigate = useNavigate()
  const [loader, setLoader] = React.useState(false)
  const dispatch = useDispatch()
  const rebaseTasksNow=async()=>{
    try {
        setLoader(true)
        navigate('/')
        setLoader(false)
        setRebaseRescheduleModal(false)
        
    } catch (error) {
        console.log("rebase tasks now", error);
        toast.error("Oops! Error encountered during task rebasing. Kindly reload the page and try again.")
        setLoader(false)
        setRebaseRescheduleModal(false)

        return false;
    }
  }

  return (
    <>
      <Modal
        open={rebaseRescheduleModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style} className="d-flex flex-column justify-content-center align-items-center">
          {loader && <Loader show={loader} />}
         <Typography id="modal-modal-title" variant="h6" component="h3" style={{fontSize:"18px", fontWeight:"700" , marginBottom:"3px"}}>
         "We've noticed your study schedule adds up to more than 24 hours a day. Let's reschedule some sessions to ensure everything fits!"
          </Typography>
          {/* <MyTooltip>  */}
          <button
            type="button"
            className="btn btn-warning homepage-btn-style study-plan-action-style"
            style={{ backgroundColor: "#1155cc", color: "white", border:"1px solid black" }}
            onClick={() => rebaseTasksNow()}
          >
            {"Reschedule Now"}
          </button>
          {/* </MyTooltip> */}
        </Box>
      </Modal>
    </>
  );
}