// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-window {
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.message {
  max-width: 70%;
  font-size: 1rem;
  line-height: 1.5;
}

.user-message {
  background: #07275d;
  color: white;
}

.bot-message {
  background: linear-gradient(to right,whitesmoke, whitesmoke);
  color: black;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.skeleton-message {
  width: 70%;
  height: 2.5rem;
  border-radius: 10px;
}
.default-message {
  color: #888;
  font-size: 1.2em;
}
.container {
  text-align: center;
  margin-top: 50px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
}

p {
  font-size: 16px;
  margin: 10px 0;
}



.question-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  text-align: center;
  width: 300px;
}

.question-button:hover {
  background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/components/chatbot/ChatWindow.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,4DAA4D;EAC5D,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;;;AAIA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".chat-window {\n  background: white;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n}\n\n.message {\n  max-width: 70%;\n  font-size: 1rem;\n  line-height: 1.5;\n}\n\n.user-message {\n  background: #07275d;\n  color: white;\n}\n\n.bot-message {\n  background: linear-gradient(to right,whitesmoke, whitesmoke);\n  color: black;\n}\n\n.avatar {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n}\n\n.skeleton-message {\n  width: 70%;\n  height: 2.5rem;\n  border-radius: 10px;\n}\n.default-message {\n  color: #888;\n  font-size: 1.2em;\n}\n.container {\n  text-align: center;\n  margin-top: 50px;\n}\n\nh4 {\n  font-size: 24px;\n  font-weight: bold;\n}\n\np {\n  font-size: 16px;\n  margin: 10px 0;\n}\n\n\n\n.question-button {\n  padding: 10px 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #f9f9f9;\n  cursor: pointer;\n  text-align: center;\n  width: 300px;\n}\n\n.question-button:hover {\n  background-color: #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
