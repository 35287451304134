import { createSlice } from "@reduxjs/toolkit";
// import { retrieveScheduleDetails } from "./scheduleAction";


const initialState = {
  userInfo: {},
  scheduleDetails: {},
  taskView:"taskViewByDay",
  userId : localStorage.getItem('userId'),
  rebaseStarted: false,
  rebaseHours: false,
  excelFilePath : " ",
  scheduleId:" ",
  trackingStarted: false,
  isLoading: true,
  isSuccess: false,
  errorMessage: '',
  isPurchased:false,
  googleCalenderSSO:false
}

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    updateScheduleDetails: (state, { payload }) => {
      console.log("payloaddddddd", payload)
      state.userInfo = payload?.user ?? state.userInfo;
      state.scheduleDetails=  payload?.json ? JSON.parse(payload?.json) : {},
      state.taskView= payload?.taskView
      state.userId = localStorage.getItem('userId'),
      state.excelFilePath = payload?.filePath,
      state.scheduleId = payload?.scheduleId,
      state.isPurchased = payload?.payment_status,
      state.trackingStarted = payload?.trackingStarted
      state.rebaseStarted =  payload?.rebaseStarted
      state.rebaseHours = payload?.rebaseHours
      state.isLoading= false,
      state.isSuccess= true,
      state.errorMessage= ''
      state.googleCalenderSSO=payload?.googleCalenderSSO == undefined ? state.googleCalenderSSO :payload?.googleCalenderSSO
    }
  },
  // extraReducers:(builder) => {
  //   builder.addCase(retrieveScheduleDetails.fulfilled, (state, { payload }) => {
  //       state.userInfo = payload?.user;
  //       state.scheduleDetails=  payload?.json ? JSON.parse(payload?.json) : {},
  //       state.taskView= payload?.taskView
  //       state.userId = localStorage.getItem('userId'),
  //       state.excelFilePath = payload?.filePath,
  //       state.scheduleId = payload?.scheduleId,
  //       state.isPurchased = payload?.payment_status,
  //       state.trackingStarted = payload?.trackingStarted == undefined ? state.trackingStarted :payload?.trackingStarte
  //       state.isLoading= false,
  //       state.isSuccess= true,
  //       state.errorMessage= ''
  //   })
  // }
})

export const { updateScheduleDetails } = scheduleSlice.actions;

export default scheduleSlice.reducer;
